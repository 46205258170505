<template>       
    <div class="row" style="margin-left:0 ;margin-bottom: 10px; gap: 20px;">     
        <input style="width: 300px;" type="text" placeholder="Nombre presupuesto" v-model="nombrepresupuesto" class="form-control input-sm">
        <button type="button" class="btn btn-default" @click="crearpresupuesto()">{{ $t('general.agregar') }}</button>
    </div>

    <div class="card card-primary card-outline card-outline-tabs">
        <div class="card-header p-0 border-bottom-0">
            <ul class="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                <li class="nav-item" v-for="presupuesto in presupuestos" :key="presupuesto.presupuesto.id_presupuesto"  :value="presupuesto">
                    <a href="#pestanya_presupuesto" class="nav-link" @click="test(presupuesto, $event);test2(); presupuesto.activo=true" data-toggle="pill" role="tab" aria-controls="custom-tabs-four-home">
                        {{ presupuesto.presupuesto.nombre_presupuesto }} <span v-if="presupuesto.presupuesto.nulo" class="float-right badge bg-danger">Nulo</span>
                    </a>
                </li>
                
            </ul>
        </div>
        <div class="card-body">
            <div class="tab-content" >
                <div class="tab-pane fade show active" id="pestanya_presupuesto-home" role="tabpanel" aria-labelledby="custom-tabs-four-home-tab">
                    <servicio-presupuesto v-if="seleccionado" :seleccionado="seleccionado" :id="id" :idaux="idaux" />
                </div>
            </div>
        </div>

    </div>
    <!-- 
    <div class="card">
        <div class="card-header bg-gray">
            <h3 class="card-title"><i class="fas fa-list mr-2"></i>{{ $t('general.presupuestos') }}</h3>    
            <div class="card-tools">
                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                    <i class="fas fa-minus"></i>
                </button>
            </div>        
        </div>
        <div class="card-body">
            <ul class="nav flex-column">
                <li class="nav-item" v-for="presupuesto in presupuestos" :key="presupuesto.presupuesto.id_presupuesto"  :value="presupuesto">
                    <a href="#" class="nav-link" @click="test(presupuesto, $event);test2(); presupuesto.activo=true">
                        {{ presupuesto.presupuesto.nombre_presupuesto }}<span v-if="presupuesto.activo==true" class="float-right badge bg-warning">Activo</span> <span v-if="presupuesto.presupuesto.nulo" class="float-right badge bg-danger">Nulo</span><span v-if="presupuesto.activo == false" class="float-right badge bg-primary">Inactivo</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
    <div class="col-md-12">
        <servicio-presupuesto v-if="seleccionado" :seleccionado="seleccionado" :id="id" :idaux="idaux" />
    </div>
    -->
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
import serviciopresupuesto from './presupuesto.vue';

export default ({ 
    props: ['id','servicio'],
    setup() {
        return;
    },
    components: {
        'servicio-presupuesto': serviciopresupuesto,

    },
    data() {
        return {
            seleccionado: '', 
            idaux: '',
            acierto: false,
            presupuestos: '',
            nombrepresupuesto:'',
        }
    },
    methods: {
        async cargardatos() {
            // GET  modulos/pwgsapi/index.php/presupuestos/:id
            const api = new PwgsApi;
            this.presupuestos = await api.get("presupuestos/" + this.$props.id);
            this.test2();
        },

        test(presupuesto, event) {
            event.preventDefault(); // Previene el comportamiento predeterminado
            this.seleccionado = presupuesto;
            this.idaux = this.seleccionado.presupuesto.id_presupuesto;
            this.test2();
        },
        test2() { 

            for (let i = 0; i < this.presupuestos.length; i++) {
                this.presupuestos[i].activo = false;
            }
        },
        async crearpresupuesto() { 
            //POST  modulos/pwgsapi/index.php/presupuestos/:id_servicio/crear-presupuesto
            const api = new PwgsApi;
            let subidadatos;
            subidadatos = { nombre: this.nombrepresupuesto };
            await api.post('presupuestos/' + this.$props.id + '/crear-presupuesto', subidadatos);
            this.nombrepresupuesto = '';
            this.cargardatos(this.$props.id);
        },
    },
    mounted() {
        this.cargardatos(this.$props.id);
    },
    watch: {
        id() {
            this.cargardatos();
        },
        seleccionado() {
               //   this.cargardatos(this.$props.id);

         }
    }
})
</script>