<template>
    <div class="card card-widget widget-user-2">
        <div class="widget-user-header bg-olive">
            <div class="widget-user-image">
                <i class="fas fa-user-cog" title="Operario" @click="operarioPulsado" style="font-size:xxx-large;position:absolute; cursor: pointer;"></i>
            </div>
            <h5 class="widget-user-username" style="font-size:20px">{{visita.visita.nombre_operario}}</h5>
            <h5 class="widget-user-desc" style="font-size:15px">{{ $t('general.operario') }}</h5>
        </div>
        <div class="card-footer p-0 bg-olive">
            <div class="row ">
                <div class="col-md-4 ">
                    <button type="button" v-if="visita.visita.movil_operario" v-on:click='llamar'
                        class="btn btn-block btn-warning btn-xs" title="Descripción teléfono 1">{{
                        visita.visita.movil_operario }}</button>
                </div>
                <div class="col-md-4 ">

                </div>
                <div class="col-md-2 ">
                    <button type="button" v-if="visita.visita.email_operario" v-on:click='email'
                        class="btn btn-block btn-warning btn-xs" title="Email">
                        <i class="fas fa-at"></i>
                    </button>
                </div>
                <div class="col-md-2 ">
                    <a href="#" @click="chat()" title="Chat">
                        <button type="button" class="btn btn-block btn-warning btn-xs" title="Chat">
                            <i class="fas fa-comments"></i>
                        </button>

                    </a>
                </div>
            </div>
        </div>
    </div>
    <form :id="visita.visita.idvisitador" method="get" :action="urlchat" target="TheWindow">
        <input type="hidden" name="vista" value="vista_chats" />
        <input type="hidden" name="destino_chat" :value="visita.visita.operarios_idoperario" />
        <input type="hidden" name="id_visita" :value="visita.visita.idvisitador" />
        <input type="hidden" name="id_servicio" :value="id" />
    </form>
</template>
<script>  

export default ({
    props: ['id', 'servicio', 'perjudicados', 'asegurado','visita'],
    setup() {     
    },
    data(){
        return {
            urlapu: localStorage.getItem('direccion'),
            urlchat: "https://" + localStorage.getItem('direccion') + "/modulos/chat/panel.php",
                    
        }
    },
    methods: {
        operarioPulsado(){       
            var idoper = this.visita.visita.operarios_idoperario;
            this.$router.push({
                name: 'Operario',
                params: {
                    id: idoper
                }
            });
        },
        llamar: function () {
            window.open("tel:" + this.visita.visita.movil_operario);
        },
        email: function () {
            this.$emit('email', this.visita.visita.operarios_idoperario);            
        },
        chat() { 
            console.log("idoperario", this.visita.visita.operarios_idoperario);
            this.urlchat = "https://" + this.urlapu + "/modulos/chat/panel.php";
            console.log("urlchat", this.urlchat);
            window.open('', 'TheWindow');
            document.getElementById(this.$props.visita.visita.idvisitador).submit();
        },
    },
    
})
</script>