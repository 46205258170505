<template>
    <div class="callout callout-warning">
        <div class="row">
            <!-- Desplegable de Plantillas -->
            <div class="col-md-6">
                <label for="plantillas">Seleccionar plantillas para importar</label>

                <!-- Mostrar "Cargando..." si está cargando -->
                <div v-if="isLoading" class="loading-spinner">
                    <i class="fas fa-spinner fa-spin"></i> Cargando...
                </div>

                <select multiple v-model="selectedPlantillas" class="form-control" :disabled="isLoading">
                    <option
                        v-for="plantilla in plantillas"
                        :key="plantilla.id"
                        :value="plantilla.id"
                    >
                        {{ plantilla.nombre }}
                    </option>
                </select>
            </div>

            <div class="col-md-12 mt-3">
                <button @click="importarPlantillas" class="btn btn-primary">Importar Plantillas</button>
            </div>
        </div>
    </div>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
export default ({
    props: ['id', 'servicio', 'operario', 'proveedor'],
    data() {
        return {
            plantillas: [], // Aquí almacenaremos las plantillas obtenidas
            selectedPlantillas: [], // Plantillas seleccionadas por el usuario
//            asegurado: {}, // Asegurado que se obtiene de listadoclientes
//            perjudicados: [], // Lista de perjudicados obtenida de listadoclientes
//            destinoSeleccionado: '', // Destino seleccionado en el desplegable
            isLoading: true, // Estado de carga inicializado como true
//            isLoadingCliente : true,
        };
    },
    methods: {
        // Método para cargar las plantillas desde la API
        async cargarPlantillas() {
            const api = new PwgsApi();
            try {
                // Llamada a la API para obtener las plantillas del servicio
                const response = await api.get(`trabajos/${this.id}/plantillas-servicio`);
                
                // Convertimos el objeto de respuesta en un array de plantillas
                this.plantillas = [];
                for(let plantilla of Object.values(response)){
                    if(plantilla.trabajos.length!=0){
                        this.plantillas.push(plantilla);
                    }
                }
            } catch (error) {
                console.error('Error al cargar las plantillas:', error);
            } finally {
                this.isLoading = false; // Finalizar la carga
            }
        },
        /*
        // Método para obtener la lista de clientes (asegurado y perjudicados)
        async listadoclientes() {
            const api = new PwgsApi();
            try {
                const response = await api.get(`servicios/${this.id}/clientes`);
                this.asegurado = response.asegurado;
                this.perjudicados = response.perjudicados;
            } catch (error) {
                console.error('Error al cargar los clientes:', error);
            } finally {
                this.isLoading = false; // Finalizar la carga
            }
        },
        */
        // Método para importar las plantillas seleccionadas
        async importarPlantillas() {
            const api = new PwgsApi();
            try {
                // Iteramos sobre cada plantilla seleccionada
                for (const plantillaId of this.selectedPlantillas) {
                    // Creamos el payload con el id_operario
                    const payload = {
                        id_operario: this.operario || null,
                    };

                    // Llamada al endpoint para importar la plantilla
                    await api.post(`trabajos/${this.id}/importar-trabajo-plantilla/${plantillaId}`, payload);
                }

                // Emitimos un evento después de importar las plantillas
                this.$emit('plantillas-importadas');
            } catch (error) {
                this.$toast.add({ severity: 'error', summary: "Error al importar trabajos", detail: error.message || error, life: 5000 });
            }
                
        }
    },
    watch: {
        // Si cambia el ID del servicio, volvemos a cargar las plantillas
        servicio() {
            this.cargarPlantillas();
            //this.listadoclientes();
        }
    },
    mounted() {
        // Cargar las plantillas cuando el componente se monta
        this.cargarPlantillas();
        //this.listadoclientes();
    }
})
</script>