<template>
    <div class="card">
        <div class="card-header bg-default">
            <h3 class="card-title"><i class="fas fa-users-cog" style="margin-right: 5px;"></i>Operarios que intervienen</h3>  
            <div class="card-tools">
               <!-- <button type="button" class="btn btn-success btn-xs" @click="guardar()">Guardar </button>-->
            </div>                          
        </div>
        <div class="card-body" >
            <div v-if="cargados" class="form-group">
                <div v-for="operario in operarios" :key="operario.id" class="custom-control custom-checkbox">
                   <input :id="operario.id" v-bind:true-value="'1'" v-bind:false-value="'0'" class="custom-control-input" type="checkbox" @change="guardar(operario)" v-model="operario.marcado">
                    <label :for="operario.id" class="custom-control-label">{{operario.nombre}}</label>
                </div>
            </div>
        </div>
    </div>    
</template>
<script>
import { PwgsApi } from '../../../../../../services/PwgsApi';

export default ({
            props: ['id','seleccionado'],

    data() {
        return {
            presupuestosaux: '',
            operarios: [],
            cargados:false,
            idopers:[],
        }
    },
    methods: {
        async cargardatos() {
            const api = new PwgsApi();
            var aux = await api.get('servicios/'+this.id+'/operarios');
            this.operarios = aux.operarios;
            this.presupuestosaux = this.seleccionado;
            this.idopers = this.presupuestosaux.operarios;
            this.operarios.forEach(operario=>{
                operario.marcado = this.idopers.includes(operario.id)? '1' : '0';
            })
            console.log("operspresup", this.presupuestosaux, this.operarios);
            this.cargados = true;
        },
        async guardar(operario) {
            const api = new PwgsApi();
            try{
                console.log('operarioselec',operario);
                if(operario.marcado == '1'){
                    this.idopers.push(operario.id);
                }else{
                    this.idopers = this.idopers.filter(id=>id!= operario.id);
                }
                await api.put('presupuestos/'+this.presupuestosaux.presupuesto.id_presupuesto+'/modificar-presupuesto',{operarios:this.idopers});
            }catch(e) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: e, life: 5000 });
            }
        },
    },
    mounted() {
        this.cargardatos();
    },
    watch: {
        seleccionado() {
            this.cargardatos();
        },
                            

    }
})
</script>