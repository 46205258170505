<template>
    <div class="card card-widget widget-user-2">
        <div class="widget-user-header bg-purple">
            <div class="widget-user-image">
                <i class="fas fa-house-user" style="font-size:xxx-large;position:absolute"></i>
            </div>
            <h5 class="widget-user-username" style="font-size:20px">{{visita.visita.nombre_cliente }}</h5>
            <h5 v-if="!perjudicado" class="widget-user-desc" style="font-size:15px">{{ $t('general.perjudicado') }}</h5>
            <h5 v-else class="widget-user-desc" style="font-size:15px">{{ $t('general.asegurado') }}</h5>
        </div>
        <div class="card-footer p-0 bg-purple">
            <div class="row ">
                <div class="col-md-4 ">
                    <button v-if="visita.visita.telefono1" type="button" v-on:click='llamar(visita.visita.telefono1)' class="btn btn-block btn-warning btn-xs"
                        :title="visita.visita.telefono1_descripcion">{{visita.visita.telefono1}}</button>
                </div>
                <div class="col-md-4 ">
                    <button v-if="visita.visita.telefono2" type="button" v-on:click='llamar(visita.visita.telefono2)' class="btn btn-block btn-warning btn-xs"
                        :title="visita.visita.telefono2_descripcion">{{ visita.visita.telefono2}}</button>
                </div>
                <div class="col-md-2 ">
                    <button type="button" v-if="visita.visita.cliente._datos.email" @click="email" class="btn btn-block btn-warning btn-xs" title="Email">
                        <i class="fas fa-at"></i>
                    </button>
                </div>
                <div class="col-md-2 ">
                    <button v-if="waActivado && visita.visita.telefono1" @click="chat(visita.visita.telefono1)" type="button" class="btn btn-block btn-warning btn-xs" title="Chat teléfono 1">
                        <i class="fab fa-whatsapp"></i>
                    </button>
                    <button v-else-if="waActivado && visita.visita.telefono2" @click="chat(visita.visita.telefono2)" type="button" class="btn btn-block btn-warning btn-xs" title="Chat teléfono 2">
                        <i class="fab fa-whatsapp"></i>
                    </button>
                    <button v-else-if="waActivado && visita.visita.telefono3" @click="chat(visita.visita.telefono3)" type="button" class="btn btn-block btn-warning btn-xs" title="Chat teléfono 3">
                        <i class="fab fa-whatsapp"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>  
import { PwgsApi } from '../../../../../services/PwgsApi';
export default ({
    props: ['id', 'servicio','visita'],
    setup() {     
    },
    data(){
        return {
            perjudicado: this.visita.visita.cliente._datos.perjudicado==0,
            waActivado:false,
            urlapu : localStorage.getItem('direccion'),
        }
    },
    methods: {
       llamar: function (tlf) {
            window.open("tel:" + tlf); //REVISAR TELEFONO 
        },
        email: function () {
            this.$emit('email', this.visita.visita.cliente._datos.idcliente);
        },
        chat(tlf){
            var url  = "https://"+this.urlapu+"/modulos/chat/panel.php?vista=vista_chats&id_cliente="+this.visita.visita.cliente._datos.idcliente+"&id_visita=0&id_servicio="+this.id+"&telefono="+tlf
            window.open(url, "Chat", "width=800, height=600");
        },
        async comprobarwa () {
            const api = new PwgsApi();
            var wa = await api.get('parametros-pwgs/whatsapp');
            if(wa.whatsapp=='1'){
                this.waActivado = true;
            }else{
                this.waActivado = false;
            }
        }
    },
    mounted(){
        this.comprobarwa();
    }
})
</script>