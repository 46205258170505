<template>
    <!--
        Planning de servicio
    -->
    <div class="row" style="justify-content: center; margin: 0">
        <div v-if="spinner" class="spinner"></div>
        <div v-show="!ocultar" class="col-md-12" @mousedown="cerrarop()" :style="difuminado">
            <vue-cal @click="sacarmes()" id="vuecalendario" :snapToTime="30" min-event-width="50" sticky-split-labels
                :min-split-width="minevent" :timeStep="mitimestep" :locale="local" v-model:active-view="vistaactiva"
                :dblclick-to-navigate="clickparanavegar" :split-days="splitDays" events-count-on-year-view
                :disableViews="disabledViews" :timeCellHeight="alturatimecel" ref="vuecal"
                :timeFrom="((propiedadescalendario.planning_hora_ini)*60)-60" @view-change="prueba"
                :timeTo="((propiedadescalendario.planning_hora_fin)*60)+60" todayButton="true"
                :events-on-month-view="false" :events-on-week-view="true" hide-content-bar
                @cell-dblclick="eventoclick($event),cargarevs($event)"
                @event-duration-change="confirmarevento($event,'redimensiona')" :on-event-click="onEventClick"
                :events="eventos" @event-drop="confirmarevento($event,'arrastra')" :editable-events="editable"
                @event-delete="eliminarVisita($event)" :selected-date="selectedDate">
                <template v-slot:event="{ event }">
                    <span @mouseover="mostrar($event,event)" class="vuecal__flex vuecal__cell-content event-span"
                        :style='"background-color:" + event.color + ";border:1px solid #000;color:black" +"display:block"'>
                        <div style="text-align:center"
                            v-if="event.title == 'GUARDIA' || event.title == 'OCUPADO' || event.title == 'BLOQUEADO' || event.title == 'VACACIONES' || event.title == 'RESERVA'">
                            {{ event.title }}
                        </div>
                        <div v-else-if="event.color == '#ffe88c' || event.color=='#E8EFF4'"
                            style="font-size:0.8em;font-weight:bold">
                            <!-- {{ decode_utf8(event.content.substring(0, event.content.indexOf(pepe))) }} -->
                            {{ decode_utf8(event.poblacion) }}&nbsp;<span v-if="event.cp"
                                :style="'background-color:' + event.color_km">({{ event.cp }})</span>
                        </div>
                        <div v-else style="font-size:0.8em;font-weight:bold;color:white">
                            {{ decode_utf8(event.poblacion) }}&nbsp;<span v-if="event.cp"
                                :style="'background-color:' + event.color_km">({{ event.cp }})</span>
                        </div>
                    </span>
                </template>
                <template #time-cell="{ hours, minutes }">
                    <div v-if="hours<propiedadescalendario.planning_hora_ini"></div>
                    <div v-if="hours>=propiedadescalendario.planning_hora_ini && minutes!='00'"
                        class="vuecal__time-cell-line miclaseplanning">
                        <span v-if="minutes!=30 && minutes != 45 && minutes != 15 && hours<10">0{{ hours }}:{{ minutes
                            }}0</span>
                        <span v-if="minutes!=30 && minutes != 45 && minutes != 15 && hours>=10">{{ hours }}:{{ minutes
                            }}0</span>

                    </div>
                    <div v-if="hours>=propiedadescalendario.planning_hora_ini && minutes=='00'"
                        class="vuecal__time-cell-line miclaseplanningdos">
                        <span v-if="minutes!=30 && minutes != 45 && minutes != 15 && hours<10">0{{ hours }}:{{ minutes
                            }}0</span>
                        <span v-if="minutes!=30 && minutes != 45 && minutes != 15 && hours>=10">{{ hours }}:{{ minutes
                            }}0</span>

                    </div>

                </template>
                <template #split-label="{ split }">
                    <table>
                        <tr>
                            <td style="line-height: 20px;">
                                <i v-if="split.autovisita==1" title="Operario configurado para Autovisita"
                                    class="fas fa-regular fa-lightbulb" :style="`color: rgb(225, 225, 1)`"></i>
                                &nbsp;<strong>{{ split.label.toString() }}</strong>
                            </td>
                        </tr>



                        <tr>
                            <td>
                                <strong v-if="split.interpwgs == 1">[INTERPWGS]</strong>
                                <strong v-if="split.preferente == 1" title="Preferente">[Preferente]</strong>
                                <strong v-if="split.guardia == 1" :title="$t('general.Guardia')">[{{
                                    $t('general.Guardia') }}]</strong>

                            </td>
                        </tr>

                        <tr>
                            <td style="text-align:center">
                                <i v-if="split.observaciones" :title="' Observaciones\n'+split.observaciones"
                                    class="pi pi-exclamation-triangle" :style="`color: #f00`"></i>
                                <strong :title="$t('general.Carga')">[{{ split.carga }}%]</strong>
                                <a href="#" :title="$t('general.Llamar')" :onclick="split.clicktocall"><i
                                        class="pi pi-phone"></i></a>
                            </td>
                        </tr>
                    </table>
                </template>
                <template #title="{ title }">

                    <!-- <span style="color:black"><i @click="$refs.vuecal.previous()" class="fas fa-angle-left">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</i></span>-->
                    <div :style="'color:black;'+colorfestivo">{{title}}<!--&nbsp;&nbsp;{{nombrefestividad}}--></div>
                    <!-- &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style="color:black"><i @click.stop="$refs.vuecal.next()" class="fas fa-angle-right"></i></span>-->

                </template>
                <template #events-count="{ events }">
                    <span @click="mostrarevento(event)" v-for="event in events" :key="event">

                        <!--     <span v-if="comprobarevento(event)==true"  class="badge badge-success"  style="font-size:medium;color:black">
                        <b> &nbsp;Festivo&nbsp;</b></span>-->

                        <span title="Activas" class="badge badge-primary" v-if="event.activas>0 && event == events[0]"
                            style="font-size:medium;background-color:#ffe88c;color:black">
                            <b> &nbsp;{{ event.activas }}&nbsp;</b>
                        </span>
                        <span title="Por confirmar" class="badge badge-primary"
                            v-if="event.confirmar>0 && event == events[0]"
                            style="font-size:medium;background-color:#f89393;color:black">
                            <b>&nbsp;{{ event.confirmar }}&nbsp;</b>
                        </span>
                        <span title="Finalizadas" class="badge badge-primary"
                            v-if="event.finalizadas>0 && event == events[0]"
                            style="font-size:medium;background-color:#e8eff4;color:black">
                            <b> &nbsp;{{ event.finalizadas }}&nbsp;</b>
                        </span>
                        <span :title="'Festivo: ' + event.festivostitle" class="badge badge-primary"
                            v-if="event.festivos && event == events[0]"
                            style="font-size:medium;background-color:#126e12;color:white">
                            <b> &nbsp;F&nbsp;</b>
                        </span>
                    </span>
                </template>
            </vue-cal>
            <OverlayPanel ref="opb" :showCloseIcon="false"><!---->
                <p>{{ descripcionbloq }}</p>
            </OverlayPanel>
            <OverlayPanel @hide="recargar()" style="width:600px" ref="op" :showCloseIcon="false" @show="onShowPanel">
                <!---->
                <b>{{nombreevento}}</b>
                <p>{{descripcionevento}}</p>

                <servicio-datos-visitas-visita :planning="1" :visita="eventoamostrar" v-if="eventoamostrar"
                    :colorbotondos="colorbotondos" :colorbotontres="colorbotontres" :colorbotoncuatro="colorbotoncuatro"
                    :colorboton="colorboton" :servicio="servicio" :id="id" :propsplanning="false" />
                <!-- <button type="button" v-show="eventoamostrar.visita && eventoamostrar.visita.confirmar==1"
                    @click="confirmarvisita(eventoamostrar)" title="Confirmar Visita" class="btn btn-default ">
                    <i class="fas fa-align-justify"></i>
                </button>
                <button type="button" class="btn btn-default " v-on:click='abrirventana' title="Parte Genérico">
                    <i class="far fa-file"></i>
                </button>
                <button type="button" class="btn btn-default " v-on:click="finalizarvisita()"
                    :style="'background:' + colorbotoncuatro" title="Finalizar Visita">
                    <i class="fas fa-check"></i>
                </button>

                <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown">
                    <i class="fas fa-share-square"></i>
                </button>

                <ul class="dropdown-menu">
                    <li class="dropdown-item"><a href="#" @click="parteoperarioemail()">{{$t('general.enviarfichaemail')
                            }}</a></li>
                    <li class="dropdown-divider"></li>
                    <li class="dropdown-item"><a href="#" @click="smsoperario()">{{ $t('general.smsaloperario') }}</a>
                    </li>
                    <li class="dropdown-item"><a href="#" @click="smsasegurado()">{{ $t('general.smsalasegurado') }}</a>
                    </li>
                    <li class="dropdown-item"><a href="#" @click="steasegurado()">{{ $t('general.firmasteemail') }}</a>
                    </li>
                </ul>-->
            </OverlayPanel>
        </div>
        <div v-show="ocultar" class="alert alert-danger" role="alert">
            <h4>No hay operarios disponibles con los filtros seleccionados</h4>
        </div>
    </div>
    <Dialog ref='dialog1' @hide="recargar()" :draggable="false" v-model:visible="visible" v-show="visible" modal
        :header="titulo" :style="{ width: altura } " @show="onShowPanel">
        <!--'50vw'-->
        <p v-text="decode_utf8(infoevento)"></p>
        <servicio-datos-visitas-visita :planning="1" :visita="eventoamostrar" v-if="eventoamostrar"
            :colorbotondos="colorbotondos" :colorbotontres="colorbotontres" @recargar="recargar()"
            :colorbotoncuatro="colorbotoncuatro" :colorboton="colorboton" :servicio="servicio" :id="id" />
        <div class="row" v-if="editar == true" style="background:#c7f08b61">
            <div class="col-md-3">
                <div class="form-group">
                    <label for="ffinidos">{{ $t('general.FechaInicio') }}</label><br>
                    <Calendar id="ffinidos" v-model="fechainicioeditar" :stepMinute="10" showTime hourFormat="24"
                        dateFormat="dd/mm/yy" />
                    <div class="custom-control custom-checkbox"> <br>
                        <input v-model="nomodificar" class="custom-control-input" type="checkbox" id="nomodificar"
                            true-value="1" false-value="0">
                        <label for="nomodificar" class="custom-control-label">{{ $t('general.nomodificar') }}</label>
                    </div>

                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label for="fechafindos">{{ $t('general.fechafin') }}</label><br>
                    <Calendar id="fechafindos" v-model="fechafineditar" showTime :stepMinute="10"
                        dateFormat="dd/mm/yy" />
                    <div class="custom-control custom-checkbox"> <br>
                        <input v-model="prioritaria" class="custom-control-input" type="checkbox" id="prioritaria"
                            true-value="1" false-value="0">
                        <label for="prioritaria" class="custom-control-label">{{ $t('general.prioritaria') }}</label>
                    </div>
                </div><br><br>
                <Button
                    @click="modificarVisita(eventoamostrar,fechainicioeditar,fechafineditar,infoevento,nomodificar,prioritaria)">{{
                    $t('general.guardar') }}</Button>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label for="descripcion">{{ $t('general.descripcion') }}</label>
                    <span class="p-float-label">
                        <Textarea autoResize v-model="infoevento" class="descripcion" />
                    </span>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                </div>
            </div>
        </div>
        <div class="row" v-if="finalizar == true">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header bg-gray">
                        <h3 class="card-title"><i class="fab fa-telegram-plane mr-2"></i>{{ $t('finalizarvisita') }}
                        </h3>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="trabajosrealizados">Trabajos realizados</label>
                                    <textarea v-model="trabajosrealizadostexto" class="form-control" rows="10" />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label for="trabajospendientes">Trabajos pendientes</label>
                                <textarea v-model="trabajospendientestexto" class="form-control" rows="10" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-5">
                            </div>
                            <div class="col-md-2">
                                <button class="btn btn-success btn-block" @click="finVisita()" type="button">Finalizar
                                    Visita</button>
                            </div>
                            <div class="col-md-5">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--<div class="row"  v-if="editar == true" style="background:#c7f08b61"><div class="col-md-5"></div> <Button>{{ $t('general.guardar') }}</Button> </div>-->
        <planningcomunicaciones :id="eventoamostrar.visita.servicios_idservicios"
            :idvisita="eventoamostrar.visita.idvisitador" v-if="mostrarcomunicaciones" />
        <trabajosrealizadosoperarios v-if="eventoamostrar && trabajos" :operariosingular="operariosingular"
            :servicio="servicio" :totalrent="totalrent" :ocultarcliente="ocultarcliente"
            :ocultaroperario="ocultaroperario" :totalcliente="totalcliente" :totaloperario="totaloperario"
            :id="eventoamostrar.visita.servicios_idservicios" />
        <presupuestos v-if="presupuestos==true" :id="eventoamostrar.visita.servicios_idservicios" />
    </Dialog>
    <Dialog ref='dialog2' draggable="false" v-model:visible="nevisible" modal
        :header="$t('general.nuevacita') + ' (' + new Date(this.eventoacrear).toLocaleDateString('es-ES') + ')'"
        :style="{ width: '65vw' }">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="operarioseleccionado">{{ $t('general.operario') }}</label><br>
                    <select style="width: 100%;" v-model="operarioseleccionado" id="operarioseleccionado"
                        class="custom-select form-control form-control-sm select operarioseleccionado">
                        <option v-for="operario in operariossplit" :key="operario.id" :value="operario.id">{{
                            operario.nombre }}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-6" v-if="propiedadescalendariooper && propiedadescalendariooper.plantillas_descripcion_visita">
                <div class="form-group">
                    <label for="operarioseleccionado">Plantilla descripción</label><br>
                    <select style="width: 100%;" v-model="plantilladescripcionseleccionada" id="operarioseleccionado"
                        class="custom-select form-control form-control-sm select operarioseleccionado">
                        <option
                            v-for="plantilla in Object.keys(propiedadescalendariooper.plantillas_descripcion_visita)"
                            :key="plantilla">{{
                            plantilla}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="visitas">Visita a</label><br>
                    <select style="width: 100%;" v-model="clienteseleccionado" id="visitas"
                        class="custom-select form-control-sm select">
                        <option v-for="cliente in clientes" :key="cliente.idcliente" :value="cliente">{{ cliente.nombre
                            }}</option>
                    </select>
                    <br>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="visitas">Descripción visita</label><br>
                    <Textarea placeholder="Descripción de la visita" style="width: 100%;" autoResize id="descripcion"
                        cols="30" v-model="descripcionvisitas"></Textarea>
                    <label>{{ descripcionvisitas.length }} carácteres. Mínimo {{ descripcionminima }}
                        carácteres.</label><br>
                    <div style="text-align: end;">
                        <button @click="agregarvisita()" class="btn btn-primary btn-sm">Añadir visita</button>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="form-group">
                    <table class="table table-sm">
                        <tbody>
                            <tr v-for="descr in clientedescripcion " :key="descr.cliente">
                                <td>
                                    <Button @click="removervisita(descr)"
                                        class="p-button p-component p-button-icon-only p-button-danger p-button-rounded p-button-text"
                                        type="button" aria-label="Cancel" data-pc-name="button"
                                        data-pc-severity="danger"><span class="p-button-icon pi pi-times"
                                            data-pc-section="icon"></span></button>
                                    {{descr.nombre}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="row" style="margin-bottom: 20px; margin-top: 10px">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="especialidadseleccionada">{{ $t('general.especialidad') }}</label><br>
                    <select style="width: 100%;" v-model="especialidadseleccionada" id="especialidadseleccionada"
                        class="custom-select form-control-sm select">
                        <option value="">Seleccione especialidad</option>
                        <option v-for="especialidad in especialidadesdos" :key="especialidad.id_especialidad"
                            :value="especialidad.id_especialidad">{{ especialidad.nombre }}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label for="hora">{{ $t('general.FechaInicio') }}</label><br>
                    <Calendar style="width: 100%;" v-model="fechainicio" class="inputfecha" id="hora" showTime
                        hourFormat="24" :stepMinute="10" dateFormat="dd/mm/yy" />
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label for="fechafin">{{ $t('general.fechafin') }}</label><br>
                    <Calendar style="width: 100%;" v-model="fechafin" class="inputfecha" id="fechafin" showTime
                        hourFormat="24" :stepMinute="10" dateFormat="dd/mm/yy" />
                </div>
            </div>
        </div>
        <div class="row">
            <div v-if="prestiacionesactivado" class="col-md-6">
                <div class="form-group">
                    <label for="prestaciones">Intervención IPAS</label><br>
                    <select style="width: 100%;" v-model="prestacionseleccionada" id="prestaciones"
                        class="custom-select form-control-sm select">
                        <option value="">Seleccione intervención</option>
                        <option v-for="prestacion in prestaciones" :key="prestacion.valor" :value="prestacion.valor">{{
                            this.decode_utf8(prestacion.texto) }}</option>
                    </select>
                </div>
            </div>
            <div v-if="trabajosservactivado && especialidadseleccionada!=''" class="col-md-6">
                <div class="form-group">
                    <label for="trabajosserv">Trabajos</label><br>
                    <select style="width: 100%;" v-model="trabajoservseleccionado" id="trabajosserv"
                        class="custom-select form-control-sm select">
                        <option value="">Seleccione trabajo</option>
                        <option v-for="trabajoserv in trabajosserv" :key="trabajoserv.id" :value="trabajoserv.id">{{
                            trabajoserv.nombre }}</option>
                    </select>
                </div>
            </div>
            <div v-if="telefonosactivado" class="col-md-6">
                <div class="form-group">
                    <label for="trabajosserv">Tlf del evento: Realizar LLAMADA / Enviar SMS / Enviar
                        WHATSAPP</label><br>
                    <Dropdown style="width: 100%;" v-model="telefonoseleccionado" :options="telefonos"
                        optionLabel="texto" optionGroupLabel="texto" optionGroupChildren="items"
                        placeholder="Selecciona un teléfono" class="w-full md:w-14rem" />
                </div>
            </div>
        </div>

        <div class="row" style="margin: 25px 0;">
            <div class="col-md-4">
                <strong>Propiedades</strong>
                <div class="form-group">
                    <div class="custom-control custom-checkbox">
                        <input v-model="prioritaria" class="custom-control-input" type="checkbox" id="prioritaria"
                            true-value="1" false-value="0">
                        <label style="font-weight: normal;" for="prioritaria" class="custom-control-label">{{
                            $t('general.prioritaria') }}</label>
                    </div>
                    <div class="custom-control custom-checkbox">
                        <input v-model="nomodificar" class="custom-control-input" type="checkbox" id="nomodificar"
                            true-value="1" false-value="0">
                        <label style="font-weight: normal;" for="nomodificar" class="custom-control-label">{{
                            $t('general.nomodificar') }}</label>
                    </div>
                    <div v-if="porconfimaractivado" class="custom-control custom-checkbox">
                        <input v-model="confirmar_cita" class="custom-control-input" type="checkbox" id="confirmarcita"
                            true-value="1" false-value="">
                        <label style="font-weight: normal;" for="confirmarcita" class="custom-control-label">Por
                            confirmar</label>
                    </div>
                    <div v-if="medicionactivada" class="custom-control custom-checkbox">
                        <input v-model="medicion" class="custom-control-input" type="checkbox" id="medicion"
                            true-value="1" false-value="0">
                        <label style="font-weight: normal;" for="medicion" class="custom-control-label">Medición</label>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div v-if="documentosactivado && documentos.length>0">
                    <strong>Documentos rellenar operario</strong>
                    <div v-for="docs in documentos" :key="docs.id" class="custom-control custom-checkbox">
                        <input v-model="docs.activated" class="custom-control-input" type="checkbox" :id="docs.nombre"
                            true-value="1" false-value="0">
                        <label style="font-weight: normal;" :for="docs.nombre" class="custom-control-label">{{
                            docs.nombre }}</label>
                    </div>
                </div>

            </div>
            <div class="col-md-4">
                <div v-if="gruposactivado && grupos.length>0">
                    <strong>Grupo campos rellenar operario</strong>
                    <div v-for="grps in grupos" :key="grps.id" class="custom-control custom-checkbox">
                        <input v-model="grps.activated" class="custom-control-input" type="checkbox" :id="grps.nombre"
                            true-value="1" false-value="0">
                        <label style="font-weight: normal;" :for="grps.nombre" class="custom-control-label">{{
                            grps.nombre }}</label>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div v-if="presupuestosactivado && presupuestoss.length>0">
                    <strong>Presupuestos rellenar operario</strong>
                    <div v-for="presups in presupuestoss" :key="presups.id" class="custom-control custom-checkbox">
                        <input v-model="presups.activated" class="custom-control-input" type="checkbox"
                            :id="presups.nombre" true-value="1" false-value="0">
                        <label style="font-weight: normal;" :for="presups.nombre" class="custom-control-label">{{
                            presups.nombre }}</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div v-if="equipos.length >= 1 && equiposactivado" class="col-md-6">
                <label for="equiposarreparar">{{ $t('general.equiposareparar') }}</label><br>
                <MultiSelect v-model="equiposseleccionado" :options="equipos" optionValue="equipo.id_equipo"
                    optionLabel="equipo.nombre_equipo" placeholder="Seleccione pedido" :maxSelectedLabels="3"
                    style="width: 100%;" />
            </div>
            <div class="col-md-6" v-if="pedidosactivado">
                <div v-if="pedidos.length >= 1" class="form-group">
                    <label for="pedidos">Pedido</label><br>
                    <MultiSelect v-model="pedidosSeleccionados" :options="pedidos" optionValue="id" optionLabel="nombre"
                        placeholder="Seleccione pedido" :maxSelectedLabels="3" style="width: 100%;" />
                </div>
            </div>
        </div>
        <div class="col-md-12" style="margin-top: 25px;">
            <div style="text-align: center;" class="form-group">
                <button @click="nuevavisita(fechainicio, fechafin, descripcionvisitas)"
                    class="btn btn-primary btn-sm">Nueva cita</button>
            </div>
        </div>

    </Dialog>
    <Dialog header="¿Quieres modificar la cita?" draggable="false" :style="{ width: '25rem' }"
        @hide="confirmacionvisible = false;recargar(); difuminado = ''" ref='dialogconfirmacion'
        v-show="confirmacionvisible" v-model:visible="confirmacionvisible" modal>
        <div class="row">
            <div class="col-md-4"></div>
            <h5 style="text-align:center"><b>{{ eventoamodificar.event.class}}</b></h5>
        </div>
        <div class="row">
            <div class="col-md-2"></div>
            <p><br><b>Original: {{ new Date(eventoamodificar.originalEvent.start).addHours(1).toLocaleTimeString()
                    }}</b> hasta
                <b>{{ new
                    Date(eventoamodificar.originalEvent.end).addHours(1).toLocaleTimeString() }}</b><br><br><b>Nuevo: {{
                    new
                    Date(eventoamodificar.event.start).addHours(1).toLocaleTimeString() }}</b> hasta <b>{{ new
                    Date(eventoamodificar.event.end).addHours(1).toLocaleTimeString() }}</b>
            </p>
        </div>
        <div class="row">
            <p></p>
        </div>
        <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-3">
                <button style="width:100%;background:green;border:0px"
                    @click="crearevento(eventoamodificar,tipoamodificar); difuminado = ''"
                    class=" btn btn-primary btn-sm">Si</button>
            </div>
            <div class="col-md-3">
                <button style="width:100%;background:red;border:0px"
                    @click="confirmacionvisible = false;recargar(); difuminado = ''"
                    class="btn btn-primary btn-sm">Cancelar</button>
            </div>
        </div>
    </Dialog>
    <Dialog v-model:visible="citaconfirmar" :style="{ width: '35rem' }" header="Confirmacion de la cita" modal>
        <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-4">
                <div class="form-group">
                    <label for="fechacitainicio">{{ $t('general.fechainicio') }}</label><br>
                    <Calendar id="fechacitainicio" style="width:fit-content" v-model="datoscitaconfirmar.fechacita"
                        showTime hourFormat="24" :stepMinute="10" dateFormat="dd/mm/yy" />
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label for="fechacitafin">{{ $t('general.fechafin') }}</label><br>
                    <Calendar id="fechacitafin" style="width:fit-content" v-model="datoscitaconfirmar.fechacitafin"
                        showTime hourFormat="24" :stepMinute="10" dateFormat="dd/mm/yy" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4"></div>
            <div class="col-md-4">
                <Button @click="aprobarconfirmar()" class=" btn btn-primary btn-sm">Confirmar Cita</Button>
            </div>
        </div>
    </Dialog>
</template>
<script>
import Dropdown from 'primevue/dropdown';
import MultiSelect from 'primevue/multiselect';
import serviciodatosvisitasvisita from '../datos/visitas/visita_datos.vue';
import Dialog from 'primevue/dialog';
import { PwgsApi } from '../../../../services/PwgsApi';
import VueCal from 'vue-cal';
import Calendar from 'primevue/calendar';
import Presupuestos from '../presupuestos/presupuestos.vue';
import 'vue-cal/dist/vuecal.css';
import Textarea from 'primevue/textarea';
import Button from 'primevue/button';
import planningcomunicaciones from './planning_comunicaciones.vue';
import trabajosrealizadosoperarios from '../trabajosrealizados/TrabajosRealizadosOperario.vue';
import OverlayPanel from 'primevue/overlaypanel';
export default ({
    props: ['id', 'servicio','operarios','operariosseleccionados','especioperario','cambios', 'cargars', 'calendariovacio','fechacald'],
    components: {
        Dialog, VueCal, Calendar,Textarea,Button, trabajosrealizadosoperarios, planningcomunicaciones,OverlayPanel,Presupuestos,
        'servicio-datos-visitas-visita': serviciodatosvisitasvisita, MultiSelect, Dropdown
        
    },
    data() {
        return {
            descripcionbloq:'',
            spinner:false,
            difuminado:'',
            porconfimaractivado:false,
            ocultar:false,
            trabajosservactivado:false,
            trabajosserv:[],
            trabajoservseleccionado:'',
            telefonosactivado:false,
            telefonos:[],
            telefonoseleccionado:'',
            prestaciones:[],
            prestiacionesactivado:false,
            prestacionseleccionada: 0,
            equiposactivado:false,
            pedidosactivado:false,
            documentosactivado:false,
            documentos:[],
            gruposactivado:false,
            grupos:[],
            presupuestosactivado:false,
            presupuestoss:[],
            medicionactivada:false,
            medicion:'0',
            pedidosSeleccionados:[],
            mesactual:new Date().getMonth(),
            minevent: "150",
            titulo: '',
            eventoamostrar: '',
            nomodificar:'0',
            infoevento: '',
            confirmacionvisible:false,
            prioritaria:'0',
            editable: { title: false, drag: true, resize: true, delete: true, create: false },
            visible: false,
            miseventos: [],
            eventos: [],
            editar: false,
            local: 'es',
            color: ["blue", "green", "orange", "red", "purple", "yellow", "silver", "aqua", "maroon","lime", "navy", "fuchsia", "olive", "aqua"],
            vistaactiva: "day",
            colorboton: "normal",
            altura:'50vw',
            splitDays: [],
            anyoactual: '',
            confirmar_cita:'',
            aux: [],
            trabajosrealizadostexto: '',
            trabajospendientestexto:'',
            mostrarpop: false,
            fechainicioeditar: new Date(),
            fechafineditar: new Date(),
            selectedDate: new Date(),
            trabajos:false,
            nevisible: false,
            eventoacrear: new Date(),
            operarioseleccionado: '',
            especialidadseleccionada: '',
            operariosespecialidad: [],
            fechainicio:'',
            fechafin: '',
            especialidadesdos: [],
            equipos: [],
            equiposanyadir:[],
            equiposseleccionado: [],
            opcionesvisita: '',
            clientes: [],
            clienteseleccionado: '',
            clickparanavegar: true,
            descripcionvisitas: '',
            descripcionext:'',
            nombreseleccionado:'',
            clientedescripcion: [],
            nombreequipo: '',
            clientesubir: [],
            operariosingular: '',
            colorbotondos: "normal",
            colorbotontres: "normal",
            colorbotoncuatro: "normal",
            mostrarcomunicaciones: false,
            tipoamodificar:'',
            finalizar: false,
            presupuestos: false,
            operariossplit: [],
            diaactual: '',
            pedidos:[],
            propiedadescalendario:'',
            //documentosrellenar: [],
            documentosanyadir: [],
            eventoamodificar:'',
            url: '',
            pepe: "(",
            mes: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            auxiliarsplit:[],
            citaconfirmar:false,
            noesguardiaofestivo: true,
            eventoaux: "",
            listadofestivos:'',
            nombrefestividad:'',
            colorfestivo: '',
            inicio : true,
            diasemanal : '',
            messemanal : '',
            añosemanal: '',
            descripcionevento: '',
            alturatimecel: 28,
            mitimestep:30, 
            nombreevento:'',
            overlayVisible:false,
            id_reserva_visita:0,
            idvisitaaux:'',
            ideventoaux: '',
            eventopabloaux: '', 
         pabloevento:'',
         pabloevent:'',  
         plantilladescripcionseleccionada:'',
        }
    },
    computed:{
        disabledViews(){
            if(Array.isArray(this.operariosseleccionados) && this.operariosseleccionados.length > 0){                
                return ["years", "year"];
            }else{
                return ["years", "year", "week"];
            }
        },        
        comprobarevento(event) {
            console.log("evento333", event);
            this.listadofestivos;
            let aux = false;
            for (let i = 0; i <= this.listadofestivos.length; i++){
                if(event.title == this.listadofestivos[i].fecha){
                    aux = true;
                }  
            else{
             aux = false;
                } 
                          
            }
            return aux;
        },
      
    },
    methods: {
        onShowPanel() {
            console.log('Panel mostrado');
        },
        cargar() {
            console.log('cargacarg');
           // this.descripcionvisitas = '';
            this.eventos = [];
            this.aux = [];
            
            this.splitDays = [];
            this.local = localStorage.getItem("locale");
            if (this.local == "cat") { this.local = "ca"; }
            //this.cargareventos();
            this.cargarespecialidades();
        },
        calculareventosactivos(eventos) {
            let contadoreventosnormales = 0;
            for (let i = 0; i < eventos.length; i++) {
                if (eventos[i].color == "#ffe88c") {
                    contadoreventosnormales++;
                }
            }
            return contadoreventosnormales;
        },
        calculareventosfinalizados(eventos) {
            let contadoreventosnormales = 0;
            for (let i = 0; i < eventos.length; i++) {
                if (eventos[i].color == "#e8eff4") {
                    contadoreventosnormales++;
                }

            }
            return contadoreventosnormales;
        },
        calculareventosporconfirmar(eventos) {
            let contadoreventosnormales = 0;
            for (let i = 0; i < eventos.length; i++) {
                if (eventos[i].color == "#ff4646") {
                    contadoreventosnormales++;
                }

            }
            return contadoreventosnormales;
        },
        mostrar(evento, event) {
            this.pabloevento = evento;
            this.pabloevent = event;
            if (event.title != 'GUARDIA' && event.title != 'OCUPADO' && event.title != 'BLOQUEADO' && event.title != 'VACACIONES' && event.title != 'RESERVA') 
                {
                console.log("evento", event);
                this.descripcionevento = event.content;

                    this.eventoaux = event;
                    this.cargarvisitaevento(event.idservicio, event.id);
                this.mostrarpop = true;
                     let horainicio = event.fecha.getHours();
                     horainicio = horainicio +1 ;
                if(horainicio<9){horainicio="0"+horainicio}
                let minutoinicio = event.fecha.getMinutes();
                if (minutoinicio < 9) { minutoinicio = "0" + minutoinicio }

                 let horafin = event.fechafin.getHours();
                horafin = horafin + 1;
                if(horafin<9){horafin="0"+horafin}
                let minutofin = event.fechafin.getMinutes();
                this.nombreevento = event.class;
                if (minutofin < 9) { minutofin = "0" + minutofin }
                                this.descripcionevento ="Inicio: " + horainicio + ":" + minutoinicio + " Fin: " + horafin + ":" + minutofin + "  " + this.decode_utf8(event.content)

                    if (event.class == "GUARDIA" || event.class == "OCUPADO") {
                        this.esguardiaofestivo = true
                    }
                this.$refs.op.toggle(evento);
                this.$refs.op.alignOverlay(evento);
                }if(event.title == 'BLOQUEADO'){
                    this.descripcionbloq = event.content;
                    this.$refs.opb.toggle(evento);
                    this.$refs.opb.alignOverlay(evento)
                }else{
                    console.log('eventogua', evento, event);
                    event.dragable = false;
                    event.resizable = false;
                    event.background = false;
                }
         
        },
        sacarhoras(fecha) {
            let minutos = new Date(fecha).getMinutes();
            let horas = new Date(fecha).getHours();
            if (minutos < 10) {
                minutos = "0" + minutos;
            }
            if (horas < 10) {
                horas = "0" + horas;
            }
            let fechamostrar = horas+1 + ":" + minutos
           
            return fechamostrar;
        },
        confirmarevento(evento,tipo) {
            this.difuminado = "filter:blur(4px)";
            this.$refs.op.hide();
            console.log('eventox', evento, tipo, evento.oldDate.getHours());
            if(tipo=='arrastra'){
                var inicioaux = new Date((evento.event.start).getTime()+30*60000);
                var finaux = new Date((evento.event.end).getTime()+30*60000);
                var auxnewdate = new Date((evento.newDate).getTime()+30*60000);
                evento.event.start = inicioaux;
                evento.event.end = finaux;
                evento.newDate = auxnewdate;
            }
            console.log('eventox2', evento, tipo);
            this.eventoamodificar = null;
            this.tipoamodificar = null;
            this.confirmacionvisible = true;
            this.eventoamodificar = evento;
            this.tipoamodificar = tipo;
        },
         async finalizarvisita() {
             this.$parent.$parent.$parent.toggleFinalizar();
             this.cargareventos();

        },
        agregarequipo() { 
            console.log("agregarequipo", this.equiposseleccionado);
            if(this.equiposseleccionado!=''){
                let subidadatos = { nombre: this.equiposseleccionado.equipo.nombre_equipo, id_equipo: this.equiposseleccionado.equipo.id_equipo };
                    this.equiposanyadir.push(subidadatos); 
            }
        },
        toggleTrabajos() {
            this.trabajos = !this.trabajos;
            if (this.trabajos == true) { this.altura = '80vw'; this.colorboton = "green" }
            else if (this.trabajos == false && this.editar == true && this.mostrarcomunicaciones == true || this.trabajos == false && this.editar == false && this.mostrarcomunicaciones == true || this.trabajos == false && this.editar == true && this.mostrarcomunicaciones == false ) { this.altura = '80vw'; this.colorboton = "normal" }
            else { this.altura = '50vw'; this.colorboton = "normal";}
        },
        toggleEditar() {
            this.editar = !this.editar;
            if (this.editar == true) { this.altura = '80vw'; this.colorbotondos = "green" }
            else if (this.editar == false && this.trabajos == true && this.mostrarcomunicaciones == true || this.editar == false && this.trabajos == false && this.mostrarcomunicaciones == true || this.editar == false && this.trabajos == true && this.mostrarcomunicaciones == false) { this.altura = '80vw'; this.colorbotondos = "normal" }
            else { this.altura = '50vw'; this.colorbotondos = "normal"; }
        },
        togglepresupuestos() {
            this.presupuestos = !this.presupuestos;
        },
        async finVisita() {
            //PUT  modulos/pwgsapi/index.php/planning-pwgs/:id_servicio/finalizar-visita/:id_visita
            //trabajosrealizadostexto trabajospendientestexto
            const api = new PwgsApi();

            try {
                let subidadatos = { trabajos_realizados_cita: this.trabajosrealizadostexto, trabajos_pendientes_cita: this.trabajospendientestexto }
                await api.put("planning-pwgs/" + this.$props.id + "/finalizar-visita/" + this.eventoamostrar.visita.idvisitador, subidadatos);
                this.cargar();
                this.visible = false;
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
            }
            catch (error) {
                if (error == "Error: Token no válido") {
                    api.get('logout');
                    localStorage.removeItem('token');
                    this.$router.push('/login');
                }
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
            this.cargareventos();
        },
        toggleFinalizar() {
            this.finalizar = !this.finalizar;
            if (this.finalizar == true) {
                this.colorbotoncuatro = "green";
            }
            else
            {
                this.colorbotoncuatro = "normal";
            }       
        },
        toggleComunicaciones() {
            this.mostrarcomunicaciones = !this.mostrarcomunicaciones;
            if (this.mostrarcomunicaciones == true) { this.altura = '80vw'; this.colorbotontres = "green" }
            else if (this.mostrarcomunicaciones == false && this.trabajos == true && this.editar == true || this.mostrarcomunicaciones == false && this.trabajos == false && this.editar == true || this.mostrarcomunicaciones == false && this.trabajos == true && this.editar == false) { this.altura = '80vw'; this.colorbotontres = "normal" }
            else { this.altura = '50vw'; this.colorbotontres = "normal"; }
        },
        agregarvisita() {
            if(!(this.descripcionvisitas.length>=this.descripcionminima)){
                alert('La decripción no contiene los carácteres mínimos.');
                return;
            }
            if(this.clienteseleccionado){
                console.log('agregarvisita', this.clienteseleccionado);
                let subidadatos = { cliente: this.clienteseleccionado.idcliente, descripcion: this.descripcionvisitas, nombre: this.clienteseleccionado.nombre};
                this.clientedescripcion.push(subidadatos);
                this.clientesubir.push(this.clienteseleccionado);
                console.log('clientesantes', this.clientes);
                console.log('clieteslec', this.clienteseleccionado);
                
                this.clientes = this.clientes.filter(cliente => cliente.idcliente !== this.clienteseleccionado.idcliente);
                this.clienteseleccionado = '';
                console.log('clietnesdespues', this.clientes); 
            }
        },
        removervisita(clientevisita) {
            for (let i = 0; i < this.clientedescripcion.length; i++)
            { if (this.clientedescripcion[i] == clientevisita) { this.clientedescripcion.splice(i, 1); } }
            this.clientes.push(clientevisita);
        },
        removerequipo(equip) {
            for (let i = 0; i < this.equiposanyadir.length; i++)
            { if (equip.id_equipo == this.equiposanyadir[i].id_equipo) { this.equiposanyadir.splice(i, 1); } }
        },
       async cargarequipos() {
           const api = new PwgsApi();
           this.equipos = await api.get("servicios/" + this.$props.id + "/equipos");
        },
        async eliminarVisita(evento) {
            //DELETE  modulos/pwgsapi/index.php/planning-pwgs/eliminar-visita/:id_visita
            const api = new PwgsApi();
            try{
                await api.delete("planning-pwgs/" + this.$props.id + "/eliminar-visita/" + evento.id_visita);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Cita borrada correctamente', life: 2000 });

            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });

                    this.cargarsoloeventos();


            }
            
        },
        recargar(){
                console.log('recarga');
                this.cargareventos('recargar');
        },
        cerrarop(){
            console.log('entraclicks')
            if(this.$refs.op && this.$refs.op.visible){
                console.log('entraclicks2', this.$refs.op, this.$refs.op.visible);
                this.$refs.op.hide();
            }
            if(this.$refs.opb && this.$refs.opb.visible){
                this.$refs.opb.hide();
            }
        },
        async cargarpropiedades(id, event) {
            //GET /planning-pwgs/137728/formulario-visita
            this.operariossplit = [];
            this.trabajosserv = [];
            this.prestaciones = [];
            this.telefonos = [];
            this.documentos = [];
            this.presupuestoss = [];
            this.grupos = [];
            this.medicion = '0';
            const api = new PwgsApi();
            this.descripcionvisitas= '';
            this.especialidadseleccionada='';
            this.prestacionseleccionada='';
            this.pedidosSeleccionados='';
            this.trabajoservseleccionado='';
            this.telefonoseleccionado='';
            
            var fechaaux = new Date(event.date);
            console.log('fechaaux', fechaaux);
            fechaaux.setHours(fechaaux.getHours()+1);
            fechaaux = new Date(fechaaux.getTime()+30*60000);
            if(fechaaux.getMinutes()>29){
                fechaaux.setMinutes(30);
            }else{
                fechaaux.setMinutes(0);
            }
            let fecha_hora = "?fecha=" + this.anyoactual + "-" + this.mesactual + "-" + this.diaactual + "&hora=" + fechaaux.getHours() + ":" + fechaaux.getMinutes();
            this.propiedadescalendariooper = await api.get('planning-pwgs/' + this.$props.id + '/formulario-visita/' + id + fecha_hora);

            var $segundos=0;
            if(this.propiedadescalendariooper.duracion_defecto_tiempo_citas > 0){
                $segundos=this.propiedadescalendariooper.duracion_defecto_tiempo_citas;
            }else{
                $segundos=3600;
            }
            var fechaauxfin = new Date(fechaaux.getTime() + $segundos * 1000);

            this.id_reserva_visita = this.propiedadescalendariooper.id_visita;

            this.eventoacrear = event.date; 
            this.fechainicio = fechaaux;
            this.fechafin = fechaauxfin;
            this.propiedadescalendariooper.mostrar.pedidos == '1'?this.pedidosactivado = true:this.pedidosactivado=false;
            this.propiedadescalendariooper.mostrar.ids_equipos == '1'?this.equiposactivado = true:this.equiposactivado=false;
            var datos = {};
            for (let dts of Object.values(this.propiedadescalendariooper.operarios_planning)){
                datos = dts;
                break;
            }
            datos.mostrar.confirmar_visita == '1'?this.porconfimaractivado = true:this.porconfimaractivado=false;
            if(this.porconfimaractivado && datos.confirmar_visita == '1'){
                this.confirmar_cita = 1;
            }else{
                this.confirmar_cita = '';
            }
            this.clientes = this.propiedadescalendariooper.clientes_servicio;
            for(let cliente of this.clientes){
                this.clienteseleccionado = cliente;
                break;
            }
            this.descripcionminima = this.propiedadescalendariooper.min_caracteres_descripcion_visita;
            if(this.propiedadescalendariooper.mostrar.medicion_cita == '1'){
                this.medicionactivada = true;                
            }else{
                this.medicionactivada = false;                
            }
            var operarios = this.propiedadescalendariooper.operarios_planning;
            for(let dc of Object.values(operarios)){
                this.operariossplit.push(dc);
                console.log("dc", dc);
                for (let i = 0; i < this.splitDays.length; i++){
                    if (dc.id == this.splitDays[i].id) {
                        this.splitDays[i].carga == dc.carga;
                        this.splitDays[i].guardia == dc.guardia;
                        this.splitDays[i].interpwgs == dc.interpwgs;
                       // this.splitDays[i].preferente == dc.preferente;
                   } 
                }
            }
            if(this.propiedadescalendariooper.mostrar.trabajos_asignados == '1'){
                this.trabajosservactivado = true;
                var trabajosservv = this.propiedadescalendariooper.trabajos_asignados;
                for(let dc of Object.values(trabajosservv)){
                    this.trabajosserv.push(dc);
                } 
            }else{
                this.trabajosservactivado = false;
                
            }
            if(this.propiedadescalendariooper.mostrar.prestaciones_ipas == '1'){
                this.prestiacionesactivado = true;
                var prestacions = this.propiedadescalendariooper.prestaciones_ipas;
                console.log('propscalend',this.propiedadescalendariooper);
                for(let dc of Object.values(prestacions)){
                    this.prestaciones.push(dc);
                }
                console.log('prestacionescargadas',this.prestaciones);
            }else{
                this.prestiacionesactivado = false;
                
            }
            if(this.propiedadescalendariooper.mostrar.telefonos_evento == '1'){
                this.telefonosactivado = true;
                var telefonos = this.propiedadescalendariooper.telefonos_evento;
                console.log('tlfr', telefonos);
                var index = 0;
                for(let cliente of telefonos){
                    var nombreentero = cliente['telefono1'].nombre.split('-');
                    var nombre = nombreentero[1];
                    var tlfs = [];
                    var clienten = {};
                    clienten.texto = nombre;
                    var num = 0;
                    for(let datos of Object.values(cliente)){
                        var tlf = datos.id;
                        var descripcion = datos.nombre.split('-')[2];
                        if(tlf == ''){
                            console.log();
                        }else{
                            var idunico = Math.floor(Math.random() * 10000);
                            var tlfa = {id:tlf, texto:'Teléfono: '+tlf+' Descripcion:'+descripcion, idUnico:idunico}
                            tlfs.push(tlfa);
                            if(index == 0 && num == 0){
                                this.telefonoseleccionado = tlfa;
                                console.log('entra1',this.telefonoseleccionado);
                            }
                            if(datos.checked == 'checked') {
                                this.telefonoseleccionado = tlfa;
                                console.log('entra2', this.telefonoseleccionado);
                            } 
                            }
                        num++;
                    }
                    clienten.items=tlfs;
                    this.telefonos.push(clienten);
                    index++;
                }
                console.log('telefonoscargados',this.telefonos);
            }else{
                this.telefonosactivado = false;
                
            }
            this.pedidos = this.propiedadescalendariooper.pedidos;
            if(this.propiedadescalendariooper.mostrar.visita_documentos == '1'){
                this.documentosactivado = true;
                var docs = this.propiedadescalendariooper.visita_documentos;
                for(let dc of Object.values(docs)){
                    if(dc.checked == 'checked'){
                        dc.activated = '1';
                    }else{
                        dc.activated = '0';
                    }
                    this.documentos.push(dc);
                }
                console.log('documentoscargados',this.documentos);
            }else{
                this.documentosactivado = false;
                
            }
            if(this.propiedadescalendariooper.mostrar.visita_presupuestos == '1'){
                this.presupuestosactivado = true;
                var presups = this.propiedadescalendariooper.visita_presupuestos;
                for(let dc of Object.values(presups)){                    
                    dc.activated = '1';                    
                    this.presupuestoss.push(dc);
                }
                console.log('presupuestoscargados',this.presupuestoss);
            }else{
                this.presupuestosactivado = false;
                
            }
            if(this.propiedadescalendario.mostrar.visita_grupos == '1'){
                this.gruposactivado = true;
                var grps = this.propiedadescalendario.visita_grupos;
                for(let dc of Object.values(grps)){
                    if(dc.checked == 'checked'){
                        dc.activated = '1';
                    }else{
                        dc.activated = '0';
                    }
                    this.grupos.push(dc);
                }
                console.log('gruposcargados',this.grupos);
            }else{
                this.gruposactivado = false;
                
            } 
        },
              async cargarformato() {
            const api = new PwgsApi();
            let franjas = await api.get('parametros-pwgs/franjas_planning');
            console.log("franjas", franjas)
            if (franjas.franjas_planning) {
                this.alturatimecel = 14;
                this.mitimestep = 15;
            }
        },
        async datosgenerales(){
            const api = new PwgsApi();
            let fecha_hora = "?fecha=" + this.anyoactual + "-" + this.mesactual + "-" + this.diaactual;
            this.propiedadescalendario = await api.get('planning-pwgs/' + this.$props.id + '/formulario-visita' + fecha_hora);
            if (this.propiedadescalendario.plantillas_descripcion_visita.length > 0) {
                let aux = Object.entries(this.propiedadescalendario.plantillas_descripcion_visita)
                let auxdos = aux[0][1];
                this.descripcionvisitas = auxdos;
            }
        },
        async cargarprops() {
            const api = new PwgsApi();
            let fecha_hora = "?fecha=" + this.anyoactual + "-" + this.mesactual + "-" + this.diaactual;
            let propsoper = await api.get('planning-pwgs/' + this.$props.id + '/formulario-visita' + fecha_hora);
            var operarios = propsoper.operarios_planning;
            for (let dc of Object.values(operarios)) {
                for (let i = 0; i < this.splitDays.length; i++) {
                    if (dc.id == this.splitDays[i].id) {

                        this.splitDays[i].carga = dc.carga;
                        this.splitDays[i].guardia = dc.guardia;
                        this.splitDays[i].interpwgs = dc.interpwgs;
                    }
                }
            }
        },
        async cargarespecialidades() {
            //get  modulos/pwgsapi/index.php/planning-pwgs/:id_servicio/especialidades
            const api = new PwgsApi();
            this.especialidades = await api.get("planning-pwgs/" + this.$props.id + "/especialidades");
        },

        async cargarsoloeventos() {
            this.eventos = [];
            const api = new PwgsApi;            
            let subidadatos = { start: this.anyoactual + "-" + this.mesactual + "-" + this.diaactual + "T00:00:00", end: this.anyoactual + "-" + this.mesactual + "-" + this.diaactual + "T23:59:00", id_servicio: this.$props.id };
            this.miseventos = await api.post("planning-pwgs", subidadatos);
            let background = false;
            let tipo = '';
            let split = '';
            let start = '';
            let end = '';
            
            for (let evento of Object.entries(this.miseventos)) {
                for (let operario of Object.entries(this.$props.operarios)) {
                    background = false;
                    tipo = evento[1].title;
                    start = new Date(evento[1].start).addHours(-1);
                    end = new Date(evento[1].end).addHours(-1);
                  /*  if (evento[1].title == "FESTIVO") {
                        evento[1].start = "01:00";
                        evento[1].end = "23:59";
                        start = "01:00";
                        end = "23:59";
                        split = operario[1].idoperario;


                    } */
                    
                    if (evento[1].title == "GUARDIA" || evento[1].title == "OCUPADO") {
                          if (evento[1].start.length < 5) { evento[1].start = "0" + evento[1].start }
                          if (evento[1].end.length < 5) { evento[1].end = "0" + evento[1].end }
                          let aux = new Date(this.$refs.vuecal.view.startDate).addHours(4).toISOString().split('T')[0] + 'T' + evento[1].start;
                          start = new Date(aux).addHours(-1);
                          let auxdos = new Date(this.$refs.vuecal.view.startDate).addHours(4).toISOString().split('T')[0] + 'T' + evento[1].end;
                          end = new Date(auxdos).addHours(-1);
                    }

                    if (operario[1].idoperario == evento[1].id_operario) {

                        split = operario[1].idoperario;
                    }
                    if (evento[1].rendering == "background") { background = true; }
                    if (evento[1].title == "GUARDIA" || evento[1].title == "OCUPADO" || evento[1].title == "BLOQUEADO" || evento[1].title == "VACACIONES" || evento[1].title == 'RESERVA') { evento[1].color = ""; background = true; }
                }
                this.nuevoevento(evento[1].title, start, end, evento[1].color, evento[1].id, evento[1].description, evento[1].iconos, evento[1].id_servicio, background, tipo, split, evento[1].id, evento[1].poblacion, evento[1].cp, evento[1].color_km);
            }
            
        },
        sumarDias(dia, mes, año, diasASumar) {
            // Dividimos la fecha en día, mes y año
            // Creamos una nueva fecha usando el constructor Date
            let fechaOriginal = new Date(año, mes - 1, dia); // mes - 1 porque los meses en JS van de 0 (enero) a 11 (diciembre)
            // Sumamos los días
            fechaOriginal.setDate(fechaOriginal.getDate() + diasASumar);
            // Formateamos la nueva fecha al formato "día-mes-año"
            let nuevoDia = fechaOriginal.getDate().toString().padStart(2, '0');
            let nuevoMes = (fechaOriginal.getMonth() + 1).toString().padStart(2, '0'); // +1 porque el mes empieza desde 0
            let nuevoAño = fechaOriginal.getFullYear();
            return `${nuevoDia}-${nuevoMes}-${nuevoAño}`;
        },
        async cargareventos(motivo) {
            var dia = this.diaactual;
            if(motivo != 'recargar'){
                this.spinner = true;
                this.difuminado = "filter:blur(4px)";
            }
            console.log('cargaeventosssssss1', dia, this.diaactual);
            setTimeout(async() => {
                if(dia == this.diaactual){
                    console.log('cargaeventos', motivo);
                    if(motivo=='mounted' || motivo=='id'){

                        console.log('cargaeventosssssss', motivo, this.$props.operarios);
                        this.splitDays = [];
                        let subidadatoss = [];           
                        let i = 0; 
            
                        for (let operario of Object.entries(this.$props.operarios)) {
                            let clicktocall = "window.open('sip:" + operario[1].telefono1 + "','_self'); return false;"
                            subidadatoss = { id: operario[1].idoperario, class: operario[1].idoperario, label: operario[1].nombre, split: operario[1].idoperario, color: this.color[i], carga: operario[1].carga_operario, clicktocall: clicktocall, observaciones: operario[1].observaciones_operario, preferente: operario[1].preferente_operario, hide: false, autovisita: operario[1].autovisita, guardia: operario[1].guardia, interpwgs: operario[1].interpwgs }
                            this.splitDays.push(subidadatoss);
                            i++;
                        }
                        this.inicio= false;
                    }
                    
                    if (this.anyoactual.endsWith(")")) {
                        this.anyoactual = this.anyoactual.substring(0,this.anyoactual.length-1);
                    }
                    
                    let subidadatos = {};
                    if (this.$refs.vuecal.isWeekView == true) {
                        var fechanueva = this.sumarDias(this.diaactual, this.mesactual, this.anyoactual, 6)
                        let [dianuevo, mesnuevo, añonuevo] = fechanueva.split('-').map(Number);
                        this.diasemanal = dianuevo;
                        this.messemanal = mesnuevo
                        this.añosemanal = añonuevo;
                        subidadatos = { start: this.anyoactual + "-" + this.mesactual + "-" + this.diaactual + "T00:00:00", end: añonuevo + "-" + mesnuevo + "-" + dianuevo + "T23:59:00", id_servicio: this.$props.id };
                    }else{
                        subidadatos = { start: this.anyoactual+"-"+this.mesactual+"-"+this.diaactual+"T00:00:00", end: this.anyoactual+"-"+this.mesactual+"-"+this.diaactual+"T23:59:00",id_servicio:this.$props.id };
                    }
                    console.log("anyoactual", this.anyoactual);
                    const api = new PwgsApi;  
                    this.miseventos = await api.post("planning-pwgs", subidadatos);
                    console.log('motivoentra', motivo, subidadatos ,this.miseventos);
                    this.eventos = [];
                    let background = false;
                    let tipo = '';
                    let split = '';
                    let start = '';
                    let end = '';
                    for (let evento of Object.entries(this.miseventos)) {
                        background = false;
                        tipo = evento[1].title;
                        if((tipo == "GUARDIA" || tipo == "OCUPADO") && this.$refs.vuecal.isWeekView == true){
                            console.log('entrasemanal');
                            start = new Date(evento[1].start).addHours(-1);
                            end = new Date(evento[1].end).addHours(-1);
                            var fechanuevaaux = '';
                            for(let day of evento[1].dow){
                                if(day == '1'){
                                    if (evento[1].start.length < 5) { evento[1].start = "0" + evento[1].start }
                                    if (evento[1].end.length < 5) { evento[1].end = "0" + evento[1].end }
                                    let aux = new Date(this.$refs.vuecal.view.startDate).addHours(4).toISOString().split('T')[0] + 'T' + evento[1].start;
                                    start = new Date(aux);
                                    let auxdos = new Date(this.$refs.vuecal.view.startDate).addHours(4).toISOString().split('T')[0] + 'T' + evento[1].end;
                                    end = new Date(auxdos);
                                    for (let operario of Object.entries(this.$props.operarios)) {
                                        if (operario[1].idoperario == evento[1].id_operario) {
                                            if(evento[1].rendering == "background") { background = true; }
                                            if (evento[1].title == "GUARDIA" || evento[1].title == "OCUPADO" || evento[1].title == "BLOQUEADO" || evento[1].title == "VACACIONES" || evento[1].title == "RESERVA" ){evento[1].color = "" }
                                            split = operario[1].idoperario;
                                            
                                            this.nuevoevento(evento[1].title, start, end, evento[1].color, evento[1].id, evento[1].description, evento[1].iconos, evento[1].id_servicio, background, tipo, split,evento[1].id,evento[1].poblacion,evento[1].cp,evento[1].color_km);   
                                        }
                                    }
                                }
                                if(day == '2'){
                                    if (evento[1].start.length < 5) { evento[1].start = "0" + evento[1].start }
                                    if (evento[1].end.length < 5) { evento[1].end = "0" + evento[1].end }
                                    fechanuevaaux = this.sumarDias(this.diaactual, this.mesactual, this.anyoactual, 1);
                                    let [dianuevo, mesnuevo, añonuevo] = fechanuevaaux.split('-').map(Number);
                                    let aux = añonuevo+"-"+mesnuevo+"-"+dianuevo + 'T' + evento[1].start;
                                    start = new Date(aux);
                                    let auxdos =añonuevo+"-"+mesnuevo+"-"+dianuevo + 'T' + evento[1].end;
                                    end = new Date(auxdos);
                                    for (let operario of Object.entries(this.$props.operarios)) {
                                        if (operario[1].idoperario == evento[1].id_operario) {
                                            if(evento[1].rendering == "background") { background = true; }
                                            if (evento[1].title == "GUARDIA" || evento[1].title == "OCUPADO" || evento[1].title == "BLOQUEADO" || evento[1].title == "VACACIONES" || evento[1].title == "RESERVA" ){evento[1].color = "" }
                                            split = operario[1].idoperario;
                                            
                                            this.nuevoevento(evento[1].title, start, end, evento[1].color, evento[1].id, evento[1].description, evento[1].iconos, evento[1].id_servicio, background, tipo, split,evento[1].id,evento[1].poblacion,evento[1].cp,evento[1].color_km);   
                                        }
                                        
                                    }
                                }
                                if(day == '3'){
                                    if (evento[1].start.length < 5) { evento[1].start = "0" + evento[1].start }
                                    if (evento[1].end.length < 5) { evento[1].end = "0" + evento[1].end }
                                    fechanuevaaux = this.sumarDias(this.diaactual, this.mesactual, this.anyoactual, 2);
                                    let [dianuevo, mesnuevo, añonuevo] = fechanuevaaux.split('-').map(Number);
                                    let aux = añonuevo+"-"+mesnuevo+"-"+dianuevo + 'T' + evento[1].start;
                                    start = new Date(aux);
                                    let auxdos =añonuevo+"-"+mesnuevo+"-"+dianuevo + 'T' + evento[1].end;
                                    end = new Date(auxdos);
                                    for (let operario of Object.entries(this.$props.operarios)) {
                                        if (operario[1].idoperario == evento[1].id_operario) {
                                            if(evento[1].rendering == "background") { background = true; }
                                            if (evento[1].title == "GUARDIA" || evento[1].title == "OCUPADO" || evento[1].title == "BLOQUEADO" || evento[1].title == "VACACIONES" || evento[1].title == "RESERVA" ){evento[1].color = "" }
                                            split = operario[1].idoperario;
                                            
                                            this.nuevoevento(evento[1].title, start, end, evento[1].color, evento[1].id, evento[1].description, evento[1].iconos, evento[1].id_servicio, background, tipo, split,evento[1].id,evento[1].poblacion,evento[1].cp,evento[1].color_km);   
                                        }
                                        
                                    }
                                }
                                if(day == '4'){
                                    if (evento[1].start.length < 5) { evento[1].start = "0" + evento[1].start }
                                    if (evento[1].end.length < 5) { evento[1].end = "0" + evento[1].end }
                                    fechanuevaaux = this.sumarDias(this.diaactual, this.mesactual, this.anyoactual, 3);
                                    let [dianuevo, mesnuevo, añonuevo] = fechanuevaaux.split('-').map(Number);
                                    let aux = añonuevo+"-"+mesnuevo+"-"+dianuevo + 'T' + evento[1].start;
                                    start = new Date(aux);
                                    let auxdos =añonuevo+"-"+mesnuevo+"-"+dianuevo + 'T' + evento[1].end;
                                    end = new Date(auxdos);
                                    for (let operario of Object.entries(this.$props.operarios)) {
                                        if (operario[1].idoperario == evento[1].id_operario) {
                                            if(evento[1].rendering == "background") { background = true; }
                                            if (evento[1].title == "GUARDIA" || evento[1].title == "OCUPADO" || evento[1].title == "BLOQUEADO" || evento[1].title == "VACACIONES" || evento[1].title == "RESERVA" ){evento[1].color = "" }
                                            split = operario[1].idoperario;
                                            
                                            this.nuevoevento(evento[1].title, start, end, evento[1].color, evento[1].id, evento[1].description, evento[1].iconos, evento[1].id_servicio, background, tipo, split,evento[1].id,evento[1].poblacion,evento[1].cp,evento[1].color_km);   
                                        }
                                        
                                    }
                                }
                                if(day == '5'){
                                    if (evento[1].start.length < 5) { evento[1].start = "0" + evento[1].start }
                                    if (evento[1].end.length < 5) { evento[1].end = "0" + evento[1].end }
                                    fechanuevaaux = this.sumarDias(this.diaactual, this.mesactual, this.anyoactual, 4);
                                    let [dianuevo, mesnuevo, añonuevo] = fechanuevaaux.split('-').map(Number);
                                    let aux = añonuevo+"-"+mesnuevo+"-"+dianuevo + 'T' + evento[1].start;
                                    start = new Date(aux);
                                    let auxdos =añonuevo+"-"+mesnuevo+"-"+dianuevo + 'T' + evento[1].end;
                                    end = new Date(auxdos);
                                    for (let operario of Object.entries(this.$props.operarios)) {
                                        if (operario[1].idoperario == evento[1].id_operario) {
                                            if(evento[1].rendering == "background") { background = true; }
                                            if (evento[1].title == "GUARDIA" || evento[1].title == "OCUPADO" || evento[1].title == "BLOQUEADO" || evento[1].title == "VACACIONES" || evento[1].title == "RESERVA" ){evento[1].color = "" }
                                            split = operario[1].idoperario;
                                            this.nuevoevento(evento[1].title, start, end, evento[1].color, evento[1].id, evento[1].description, evento[1].iconos, evento[1].id_servicio, background, tipo, split,evento[1].id,evento[1].poblacion,evento[1].cp,evento[1].color_km);   
                                        }     
                                    }
                                }
                                if(day == '6'){
                                    if (evento[1].start.length < 5) { evento[1].start = "0" + evento[1].start }
                                    if (evento[1].end.length < 5) { evento[1].end = "0" + evento[1].end }
                                    fechanuevaaux = this.sumarDias(this.diaactual, this.mesactual, this.anyoactual, 5);
                                    let [dianuevo, mesnuevo, añonuevo] = fechanuevaaux.split('-').map(Number);
                                    let aux = añonuevo+"-"+mesnuevo+"-"+dianuevo + 'T' + evento[1].start;
                                    start = new Date(aux);
                                    let auxdos =añonuevo+"-"+mesnuevo+"-"+dianuevo + 'T' + evento[1].end;
                                    end = new Date(auxdos);
                                    for (let operario of Object.entries(this.$props.operarios)) {
                                        if (operario[1].idoperario == evento[1].id_operario) {
                                            if(evento[1].rendering == "background") { background = true; }
                                            if (evento[1].title == "GUARDIA" || evento[1].title == "OCUPADO" || evento[1].title == "BLOQUEADO" || evento[1].title == "VACACIONES" || evento[1].title == "RESERVA" ){evento[1].color = "" }
                                            split = operario[1].idoperario;
                                            this.nuevoevento(evento[1].title, start, end, evento[1].color, evento[1].id, evento[1].description, evento[1].iconos, evento[1].id_servicio, background, tipo, split,evento[1].id,evento[1].poblacion,evento[1].cp,evento[1].color_km);   
                                        }
        
                                    }
                                }
                                if(day == '7'){
                                    if (evento[1].start.length < 5) { evento[1].start = "0" + evento[1].start }
                                    if (evento[1].end.length < 5) { evento[1].end = "0" + evento[1].end }
                                    fechanuevaaux = this.sumarDias(this.diaactual, this.mesactual, this.anyoactual, 6);
                                    let [dianuevo, mesnuevo, añonuevo] = fechanuevaaux.split('-').map(Number);
                                    let aux = añonuevo+"-"+mesnuevo+"-"+dianuevo + 'T' + evento[1].start;
                                    start = new Date(aux);
                                    let auxdos =añonuevo+"-"+mesnuevo+"-"+dianuevo + 'T' + evento[1].end;
                                    end = new Date(auxdos);
                                    for (let operario of Object.entries(this.$props.operarios)) {
                                        if (operario[1].idoperario == evento[1].id_operario) {
                                            if(evento[1].rendering == "background") { background = true; }
                                            if (evento[1].title == "GUARDIA" || evento[1].title == "OCUPADO" || evento[1].title == "BLOQUEADO" || evento[1].title == "VACACIONES" || evento[1].title == "RESERVA" ){evento[1].color = "" }
                                            split = operario[1].idoperario;
                                            
                                            this.nuevoevento(evento[1].title, start, end, evento[1].color, evento[1].id, evento[1].description, evento[1].iconos, evento[1].id_servicio, background, tipo, split,evento[1].id,evento[1].poblacion,evento[1].cp,evento[1].color_km);   
                                        }
                                        
                                    }
                                }
                            }
                        }else{
                            start = new Date(evento[1].start).addHours(-1);
                            end = new Date(evento[1].end).addHours(-1);
                            /*if (evento[1].title == "FESTIVO") {
                                evento[1].id_operario = operario[1].idoperario;
                                evento[1].start = "01:00";
                                evento[1].end = "23:59";
                                start = evento[1].start;
                                end = evento[1].end;
                            } */
                            if (evento[1].title == "GUARDIA" || evento[1].title == "OCUPADO") {
                                if (evento[1].start.length < 5) { evento[1].start = "0" + evento[1].start }
                                if (evento[1].end.length < 5) { evento[1].end = "0" + evento[1].end }
                                let aux = new Date(this.$refs.vuecal.view.startDate).addHours(4).toISOString().split('T')[0] + 'T' + evento[1].start;
                                start = new Date(aux).addHours(-1);
                                let auxdos = new Date(this.$refs.vuecal.view.startDate).addHours(4).toISOString().split('T')[0] + 'T' + evento[1].end;
                                end = new Date(auxdos).addHours(-1);
                            }
                            console.log("testoperarios", this.$props.operarios);
                            for (let operario of Object.entries(this.$props.operarios)) {
                                if (operario[1].idoperario == evento[1].id_operario) {
                                    if(evento[1].rendering == "background") { background = true; }
                                    if (evento[1].title == "GUARDIA" || evento[1].title == "OCUPADO" || evento[1].title == "BLOQUEADO" || evento[1].title == "VACACIONES" || evento[1].title == "RESERVA" ){evento[1].color = "" }
                                    split = operario[1].idoperario;
                                    console.log('tiempos',evento[1].title, start,end);
                                    this.nuevoevento(evento[1].title, start, end, evento[1].color, evento[1].id, evento[1].description, evento[1].iconos, evento[1].id_servicio, background, tipo, split,evento[1].id,evento[1].poblacion,evento[1].cp,evento[1].color_km);   
                                }
                                
                            }
                        }
                    }
                    if(motivo != 'recargar'){
                        this.difuminado = "";
                        this.spinner=false;
                    }
                }else{
                    console.log('cambiodedia');
                }
            },1500);
        },
         async crearevento(event,tipo) { 
          //PUT  modulos/pwgsapi/index.php/planning-pwgs/:id_servicio/mover-visita/:id_visita
             const api = new PwgsApi();
            console.log('event', event);
            if (tipo == 'arrastra') {
                try {
                    var fechainicio = new Date(event.originalEvent.start);
                    console.log('fechas', event.newDate, fechainicio);
                    let segundos = ((new Date(event.newDate).getTime() - fechainicio.getTime()) / 1000);
                    let subidadatos = { tipo: tipo, segundos: segundos, id_operario: event.newSplit };
                    await api.put("planning-pwgs/" + event.event.idservicio + "/mover-visita/" + event.event.id_visita, subidadatos);
                    this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Evento cambiado correctamente', life: 2000 });
                 }
                catch (error) {
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
                    this.cargarsoloeventos();
                }         
             }
             if (tipo == 'redimensiona') {
                try {
                    let segundos = ((new Date(event.event.end).getTime() - new Date(event.originalEvent.end).getTime()) / 1000);
                    let subidadatos = { tipo: tipo, segundos: segundos };
                    await api.put("planning-pwgs/" + this.$props.id + "/mover-visita/" + event.event.id_visita, subidadatos);
                    this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Evento redimensionado correctamente', life: 2000 });
                 }
                catch (error)
                {
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
                    this.cargarsoloeventos();
                }       
                }
             this.confirmacionvisible = false;
        },
        eventoclick(event) {
            if (this.$refs.vuecal.isWeekView == true)
            {
                this.$refs.vuecal.currentView = 'Week';
            }
            this.nomodificar = '0';
            this.prioritaria = '0';
            this.equiposseleccionado = ''; 
            this.equiposanyadir = [];
            this.clientedescripcion = [];
            this.clienteseleccionado = ''; 
            this.cargarequipos();
            this.especialidadesdos = [];
            this.operarioseleccionado = event.split;
            this.cargarpropiedades(this.operarioseleccionado, event);            
            this.cargarespecialidadoperarios();
            if (this.$refs.vuecal.isMonthView == false)
            {
                setTimeout(() => {
                    if (this.propiedadescalendariooper && this.propiedadescalendariooper.plantillas_descripcion_visita) {
                        this.plantilladescripcionseleccionada = Object.keys(this.propiedadescalendariooper.plantillas_descripcion_visita)[0];
                        console.log('plantilladescripcionseleccionada', this.plantilladescripcionseleccionada);
                    }
                },1000)
               
                this.nevisible = true;
            }
        },
        async cargarespecialidadoperarios() { 
            // operarios/:id/especialidades
            const api = new PwgsApi();
            let especialidadesaux = await api.get('operarios/' + this.operarioseleccionado + '/especialidades');
            this.especialidadesdos = [];
            for (let especialidad of Object.entries(this.especialidades)) {
                for (let i = 0; i < especialidadesaux.especificas.datos.length; i++) {
                    if (especialidadesaux.especificas.datos[i].idespecialidad == especialidad[1].id_especialidad) { this.especialidadesdos.push(especialidad[1]);  }
                }
                for (let j = 0; j < especialidadesaux.no_especificas.datos.length; j++) {
                    if (especialidadesaux.no_especificas.datos[j].idespecialidad == especialidad[1].id_especialidad) { this.especialidadesdos.push(especialidad[1]); }
                }
            }
        },
        nuevoevento(titulo, inicio, fin, color, id, description, iconos, idservicio, background, tipo, split, id_visita, poblacion, cp, color_km) {
            let mievento = { title: titulo, start: new Date(inicio), end: new Date(fin), color: color, id: id, content: this.decode_utf8(description), fecha: inicio, fechafin: fin, iconos: iconos, idservicio: idservicio, background: background, class: tipo, split: split, draggable: true, deletable: true, id_visita: id_visita, poblacion: poblacion, cp: cp, color_km: color_km };
            this.eventos.push(mievento);
            console.log('eventosañadud',this.eventos);
        },
        decode_utf8(s) {
             try{
                return decodeURIComponent(escape(s)); 
            }
            catch(error){
                console.log(error);
                return s;
            }
        },
            async parteoperarioemail() {
            //PUT  modulos/pwgsapi/index.php/planning-pwgs/:id_servicio/enviar-ficha-operario/:id_visita
            const api = new PwgsApi();
            await api.put("planning-pwgs/" + this.$props.id + "/enviar-ficha-operario/" + this.eventoamostrar.visita.idvisitador);
        },
        onEventClick(event) {
            this.eventopabloaux = event;
            this.$refs.op.hide();
            this.eventoamostrar = '';
            this.titulo = event.title + " || " + new Date(event.fecha).toLocaleDateString("es-ES", { day: 'numeric' }) + "-" + new Date(event.fecha).toLocaleDateString("es-ES", { month: 'short' }) + "-" + new Date(event.fecha).toLocaleDateString("es-ES", { year: 'numeric' }) + " " + new Date(event.fecha).addHours(1).toLocaleTimeString("es-ES", { hour: '2-digit', minute: '2-digit' }) + "-" + new Date(event.fechafin).addHours(1).toLocaleTimeString("es-ES", { hour: '2-digit', minute: '2-digit' });
            this.infoevento = event.content;
            this.trabajos = false;
            this.fechainicioeditar = event.fecha;
            this.fechafineditar = event.fechafin;
            this.altura = '50vw';
            if (event.title != 'GUARDIA' && event.title != 'OCUPADO' && event.title != 'BLOQUEADO' && event.title != 'VACACIONES' && event.title != 'RESERVA') {
                this.visible = true;
            }
            this.editar = false;
            this.finalizar = false;
            this.colorbotondos = "normal";
            this.colorboton = "normal";
            this.colorbotontres = "normal";
            this.colorbotoncuatro = "normal"; 
            this.cargarvisitaevento(event.idservicio, event.id);
        },
        cerrarvisible() {
            this.visible = false; 
           // this.mostrar(this.pabloevento, this.pabloevent);
           // this.onEventClick(this.eventopabloaux);
            
        },
        prueba(event) {
            console.log("hola",event);
            if (event.view == "week")
              {
               // this.splitDays = [];
                this.editable.drag = false;
                this.editable.resize = false;
            }
            if (event.view == "month") {
                this.totalcitas(this.anyoactual, this.mesactual);
            }
             if(event.view == "day") {
              //this.splitDays = this.aux;
                this.editable.drag = true;
                this.editable.resize = true;
            }   
        },
         async smsoperario() {
            //PUT  modulos/pwgsapi/index.php/planning-pwgs/:id_servicio/enviar-sms-operario/:id_visita
            const api = new PwgsApi();
            try{
                await api.put("planning-pwgs/" + this.$props.id + "/enviar-sms-operario/" + this.eventoamostrar.visita.idvisitador);
                this.$toast.add({ severity: 'success', summary: 'Enviado', detail: 'Sms enviado al operario correctamente', life: 2000 });
            }
             catch (error) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
        },
        async smsasegurado() {
            //PUT  modulos/pwgsapi/index.php/planning-pwgs/:id_servicio/enviar-sms-asegurado/:id_visita
            const api = new PwgsApi();
            try {
                await api.put("planning-pwgs/" + this.$props.id + "/enviar-sms-asegurado/" + this.eventoamostrar.visita.idvisitador);
                this.$toast.add({ severity: 'success', summary: 'Enviado', detail: 'Sms enviado al asegurado correctamente', life: 2000 });
            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
        },
        async steasegurado() {
            //PUT  modulos/pwgsapi/index.php/planning-pwgs/:id_servicio/enviar-ste-firma/:id_visita
            const api = new PwgsApi();
            try{
                await api.put("planning-pwgs/" + this.$props.id + "/enviar-ste-firma/" + this.eventoamostrar.visita.idvisitador);
                this.$toast.add({ severity: 'success', summary: 'Enviado', detail: 'Ste enviado correctamente', life: 2000 });
            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
        },
        async cargarvisitaevento(idvisita, idevento) {
            //GET  modulos/pwgsapi/index.php/servicios/:id/visitas
            const api = new PwgsApi();
            let datos; 
            this.idvisitaaux = idvisita;
            this.ideventoaux = idevento;
            if (idvisita){
                datos = await api.get('servicios/' + idvisita + '/visitas');
                for (let i = 0; i < datos.length; i++) {
                    if (datos[i].visita.datos.idvisitador == idevento) {
                        this.eventoamostrar = datos[i];
                        this.operariosingular = this.eventoamostrar.visita.operarios_idoperario;
                    }
                }
            }
        },
        async cargaroperariosespecialidades() {
            this.operariosespecialidad = [];
            this.operarioseleccionado = ''; 
            for (let operario of Object.entries(this.$props.operarios)) {
                for (let especialidad of Object.entries(this.especialidades)) {
             /**/       if (operario[1].idoperario == especialidad[1].operarios_idoperario ) {
                        this.operariosespecialidad.push(operario);
                    }
                }
            }
        },
        async opcionesvisitaoperario() {
            //GET  modulos/pwgsapi/index.php/planning-pwgs/:id_servicio/opciones-operario-visita/:id_operario
            const api = new PwgsApi();
            this.opcionesvisita = await api.get("planning-pwgs/" + this.$props.id + "/opciones-operario-visita/" + this.operarioseleccionado);
        },
        async nuevavisita(finicio, ffin) {
            //POST  modulos/pwgsapi/index.php/planning-pwgs/:id_servicio/crear-visita
            let fechainicio = new Date(finicio).toLocaleDateString('es-ES');
            let fechafin = new Date(ffin).toLocaleDateString('es-ES');
            let formatohorai = new Date(finicio).getHours();
            if (formatohorai < 10) formatohorai = "0" + formatohorai;
            let formatohoraf = new Date(ffin).getHours();
            if (formatohoraf < 10) formatohoraf = "0" + formatohoraf;
            let formatomini = new Date(finicio).getMinutes();
            if (formatomini < 10) formatomini = "0" + formatomini;
            let formatominf = new Date(ffin).getMinutes();
            if (formatominf < 10) formatominf = "0" + formatominf;
            let horainicio = formatohorai + ":" + formatomini + ":00";
            let horafin = formatohoraf + ":" + formatominf + ":00";
            var id_equipos = this.equiposseleccionado;
            var clientes = [];
            if(this.clientedescripcion.length == 0){
                console.log('entra1');
                this.agregarvisita();
            }
            if(this.clientedescripcion.length == 0){
                console.log('entra2');
                return;
            }
            for(let cl of this.clientedescripcion){
                var cliente = {id:cl.cliente, descripcion:cl.descripcion};
                clientes.push(cliente);
            }
            var id_visita_documentos = [];
            if(this.documentosactivado == true){
                for(let doc of this.documentos){
                    if(doc.activated == '1')id_visita_documentos.push(doc.id);
                }
            }
            var id_visita_grupos = [];
            if(this.gruposactivado == true){
                for(let gr of this.grupos){
                    if(gr.activated == '1')id_visita_grupos.push(gr.id);
                }
            }
            var id_visita_presupuestos = [];
            if(this.presupuestosactivado == '1'){
                for(let pr of this.presupuestoss){
                    if(pr.activated == '1')id_visita_presupuestos.push(pr.id);
                }
            }
            var tlf = ''; 
            if(this.telefonoseleccionado != '')tlf = this.telefonoseleccionado.id;
            
            if(this.id_reserva_visita>0){
                this.id_visita = this.id_reserva_visita;
            }
            let subidadatos = { id_servicio: this.$props.id, fecha_cita_inicio: fechainicio, fecha_cita_fin: fechafin, hora_cita_inicio: horainicio, hora_cita_fin: horafin, reserva: 0, vacaciones: 0, clientes: clientes, id_prestacion_servicio: this.prestacionseleccionada, id_operario: this.operarioseleccionado, id_visita: this.id_visita, id_especialidad: this.especialidadseleccionada, medicion_cita: this.medicion, confirmar_cita: this.confirmar_cita, prioritaria_cita: this.prioritaria, no_modificar_cita: this.nomodificar, ids_equipos: id_equipos, visita_documentos: id_visita_documentos, visita_grupos: id_visita_grupos, visita_presupuestos: id_visita_presupuestos, ids_pedidos: this.pedidosSeleccionados, telefono_evento: tlf };
            console.log("subidas", subidadatos);
            const api = new PwgsApi();
            try{
                await api.post("planning-pwgs/" + this.$props.id + "/crear-visita", subidadatos);
                this.$toast.add({ severity: 'success', summary: 'Enviado', detail: 'Cita creada correctamente', life: 2000 });
                this.nevisible = false;    
                this.cargareventos();
            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
                this.cargareventos();
            }
        },
        anyadirdoc(id, checked) {
            if (checked == 1) {
                this.documentosanyadir.push(id);
            }
            if(checked == 0){
                for (let i = 0; i < this.documentosanyadir.length; i++)
                {
                    if (this.documentosanyadir[i] == id)
                        this.documentosanyadir[i] = '';
                    }    
            }
        },
        splitcheck() {
            if (this.$refs.vuecal.isDayView == false) {
                this.splitDays = [];
            }
          //  else { this.splitDays = this.aux }
        },
        /*async cargardocumentoscampos() {
            //GET  modulos/pwgsapi/index.php/servicios/:id/gestion-doc
            const api = new PwgsApi();
            let aux = await api.get('servicios/' + this.$props.id + '/gestion-doc');
            this.documentosrellenar = aux.gestiondoc.visitadores;
        },*/
        mostrarevento(dato) {
            console.log("evento666",dato); 
        },
        confirmarvisita(event) {
            this.citaconfirmar = true;
            this.datoscitaconfirmar = event;
            this.datoscitaconfirmar.fechacita = new Date(event.visita.fecha + "T" + event.visita.hora).addHours(1);
            this.datoscitaconfirmar.fechacitafin = new Date(event.visita.fecha_fin + "T" + event.visita.hora_fin).addHours(1);
        },
       async  aprobarconfirmar() {
             // modulos/pwgsapi/index.php/planning-pwgs/:id_servicio/confirmar-visita/:id_visita
            let fechainicio = new Date(this.datoscitaconfirmar.fechacita).toLocaleDateString('es');
            let fechafin = new Date(this.datoscitaconfirmar.fechacitafin).toLocaleDateString('es');
            let horafin = new Date(this.datoscitaconfirmar.fechacitafin).toLocaleTimeString("es-ES", { hour: '2-digit', minute: '2-digit' })
            let horainicio = new Date(this.datoscitaconfirmar.fechacita).toLocaleTimeString("es-ES", { hour: '2-digit', minute: '2-digit' })
            let subidadatos = { fecha_cita_inicio: fechainicio, fecha_cita_fin: fechafin, hora_cita_fin:horafin, hora_cita_inicio: horainicio };
            const api = new PwgsApi();
           try {
               await api.put('planning-pwgs/' + this.$props.id + '/confirmar-visita/' + this.datoscitaconfirmar.visita.idvisitador, subidadatos);
               this.$toast.add({ severity: 'success', summary: 'Confirmada', detail: 'Cita confirmada para el dia '+fechainicio+' a las '+horainicio+ 'hasta '+horafin, life: 2000 });
               this.citaconfirmar = false;
               this.cargarsoloeventos();
           }
           catch (error) {
               this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
        },
       async modificarVisita(evento, fechini, fechfin, descrevento, modificar, prioritaria) {
            //PUT  modulos/pwgsapi/index.php/planning-pwgs/:id_servicio/modificar-visita/:id_visita
            let fechainicio = new Date(fechini).toLocaleDateString('es-ES');
            let fechafin = new Date(fechfin).toLocaleDateString('es-ES');
            let formatohorai = new Date(fechini).getHours();
            if (formatohorai < 10) formatohorai = "0" + formatohorai;
            let formatohoraf = new Date(fechfin).getHours();
            if (formatohoraf < 10) formatohoraf = "0" + formatohoraf;
            let formatomini = new Date(fechini).getMinutes();
            if (formatomini < 10) formatomini = "0" + formatomini;
            let formatominf = new Date(fechfin).getMinutes();
            if (formatominf < 10) formatominf = "0" + formatominf;
            let horainicio = formatohorai + ":" + formatomini + ":00";
            let horafin = formatohoraf + ":" + formatominf + ":00";
            const api = new PwgsApi();
            //evento.visita.idvisitador
            let subidadatos = { fecha_cita_inicio: fechainicio, fecha_cita_fin: fechafin, hora_cita_inicio: horainicio, hora_cita_fin: horafin, descripcion_cita: descrevento, prioritaria_cita: prioritaria };
           try {
               await api.put("planning-pwgs/" + this.$props.id + "/modificar-visita/" + evento.visita.idvisitador, subidadatos);
               this.visible = false;
               this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
           }
           catch (error) {
               this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
               this.cargarsoloeventos();
            }         
        },
       
        sacarmes() {
            console.log('cargames');
            this.anyoactual = this.$refs.vuecal.viewTitle.substr(this.$refs.vuecal.viewTitle.length - 5);
            let aux = new Date(this.$refs.vuecal.view.startDate).getDate();
            if (aux < 10) { aux = "0" + aux; }
            this.diaactual = aux;
            if (this.$refs.vuecal.viewTitle.includes("Enero")) {
                this.mesactual = "01";
            }
             if (this.$refs.vuecal.viewTitle.includes("Febrero")) {
                this.mesactual = "02";
            }
             if (this.$refs.vuecal.viewTitle.includes("Marzo")) {
                this.mesactual = "03";
            }
            if (this.$refs.vuecal.viewTitle.includes("Abril")) {
                this.mesactual = "04";
            }
             if (this.$refs.vuecal.viewTitle.includes("Mayo")) {
                this.mesactual = "05";
            }
             if (this.$refs.vuecal.viewTitle.includes("Junio")) {
                this.mesactual = "06";
            }
             if (this.$refs.vuecal.viewTitle.includes("Julio")) {
                this.mesactual = "07";
            }
            if (this.$refs.vuecal.viewTitle.includes("Agosto")) {
                this.mesactual = "08";
            }
            if (this.$refs.vuecal.viewTitle.includes("Septiembre")) {
                this.mesactual = "09";
            }
            if (this.$refs.vuecal.viewTitle.includes("Octubre")) {
                this.mesactual = "10";
            }
            if (this.$refs.vuecal.viewTitle.includes("Noviembre")) {
                this.mesactual = "11";
            }
            if (this.$refs.vuecal.viewTitle.includes("Diciembre")) {
                this.mesactual = "12";
            }
        },
        cargarevs(event) {
            if (this.$refs.vuecal.isMonthView == true) {
                let aux;
                aux = new Date(event).getDate();
                if (aux < 10) { aux = "0" + aux; }
                this.diaactual = aux;

                
            }

        },
         async totalcitas(anyo, mes) {
            //planning-pwgs/0/visitas-mes-dia?fecha=2024-09-01
            this.eventos = [];
            
            const api = new PwgsApi();
            console.log("anyo",parseInt(anyo));
             let datos = await api.get("planning-pwgs/0/visitas-mes-dia?fecha=" + parseInt(anyo) + "-" + parseInt(mes) + "-01");
             let festivos = await api.get('festivos/' + parseInt(anyo));
 
            for (let visita of Object.entries(datos)) {
                let subidadatos = [];
                console.log("visita123", visita);
                subidadatos.start = new Date(visita[0]);
                subidadatos.end = new Date(visita[0]).addHours(4);
                subidadatos.title = visita[0];
                subidadatos.activas = visita[1].activas;
                subidadatos.confirmar = visita[1].confirmar;
                subidadatos.finalizadas = visita[1].finalizadas;
                this.eventos.push(subidadatos);
            }
             for (let i = 0; i < festivos.datos.length; i++) {
                 let subidadatos = [];

                 subidadatos.start = new Date(festivos.datos[i].fecha);
                 subidadatos.end = new Date(festivos.datos[i].fecha).addHours(4);
                 subidadatos.festivostitle = festivos.datos[i].nombre;
                 subidadatos.festivos = "F";
                 this.eventos.push(subidadatos);
             }
        },
        async cargarfestivos() {
            this.events = [];
            this.eventosaeliminar = [];
            const api = new PwgsApi();
            this.anyoactual = this.anyoactual.replace(" ","")
            let aux = await api.get('festivos/' + this.anyoactual);
            this.listadofestivos = aux.datos;
            console.log("festivos", aux);
        },
        comprobarfestivos() {
            let auxfestivos = false;
            console.log("listadofestivos", this.listadofestivos);
            for (let i = 0; i < this.listadofestivos.length; i++){
                if (new Date(this.listadofestivos[i].fecha).getFullYear() == this.anyoactual && new Date(this.listadofestivos[i].fecha).getMonth() + 1 == this.mesactual && new Date(this.listadofestivos[i].fecha).getDate()==this.diaactual){
                    document.getElementsByClassName("vuecal__flex vuecal__title")[0].style.background = '#28a745';
                    this.nombrefestividad = "Festivo " + this.listadofestivos[i].nombre;
                        auxfestivos = true;
                }
            }
            if (auxfestivos == false) {
                document.getElementsByClassName("vuecal__flex vuecal__title")[0].style.background = '#e5e5e5';
                this.nombrefestividad = " ";

            }
        },
        refresh() {
                   this.diaactual = this.diaactual - 1;
 
             setTimeout(() => {
        this.diaactual = this.diaactual + 1;
    
        }, 1000);
        }
    },
   
    mounted() {
        this.datosgenerales();
        this.sacarmes();
        this.cargar();
        //this.cargardocumentoscampos();
        //this.cargareventos();
        this.cargarfestivos();
        this.mesactual = this.mesactual + 1;
        this.cargareventos('mounted');
        /*
        if (this.$refs.vuecal.isMonthView==true){
                this.totalcitas(this.anyoactual, this.mesactual);
            }
            else{
            console.log("diaactual", this.diaactual);
            this.cargareventos();
            this.comprobarfestivos();
            for (let i = 0; i < this.splitDays.length; i++) {
                this.splitDays[i].hide = true;
                if (this.$props.operariosseleccionados.length < 1) {
                    this.splitDays[i].hide = false;
                }
                for (let j = 0; j < this.$props.operariosseleccionados.length; j++) {
                    if (this.splitDays[i].id == this.$props.operariosseleccionados[j]) {
                        console.log("operarioseleccionados11");
                        this.splitDays[i].hide = false;
                    }
                }
            }
        //this.$parent.reiniciaroperarios(); 
        }*/
        setTimeout(() => {
        this.diaactual = this.diaactual - 1;
        this.diaactual = this.diaactual + 1;
    
        }, 1000);
    },
    watch: {
        id() {
            this.cargareventos('id');
        },
        cargars(){
            this.cargarsoloeventos();
        },
        telefonoseleccionado(){
            console.log('tlfselec', this.telefonoseleccionado);
        },     
        plantilladescripcionseleccionada() { 
            console.log("entra123");
            this.descripcionvisitas = this.propiedadescalendariooper.plantillas_descripcion_visita[this.plantilladescripcionseleccionada];
            
        },   
        diaactual() {
            if (this.$refs.vuecal.isMonthView==true){
                this.totalcitas(this.anyoactual, this.mesactual);
                document.getElementsByClassName("vuecal__flex vuecal__title")[0].style.background = '#e5e5e5';
                this.nombrefestividad = " ";
            }
            else{
            console.log("diaactual", this.diaactual, this.operariosseleccionados);
            this.cargareventos('dia');
            
            this.comprobarfestivos();
            for (let i = 0; i < this.splitDays.length; i++) {
                this.splitDays[i].hide = true;
                if (this.$props.operariosseleccionados.length < 1) {
                    this.splitDays[i].hide = false;
                }
                for (let j = 0; j < this.$props.operariosseleccionados.length; j++) {
                    if (this.splitDays[i].id == this.$props.operariosseleccionados[j]) {
                        console.log("operarioseleccionados11");
                        this.splitDays[i].hide = false;
                    }
                }
            }
            
           // this.$parent.reiniciaroperarios(); 
            console.log("operarioseleccionados", this.$props.operariosseleccionados);
            console.log("splitdays123", this.splitDays.length);
                this.cargarprops();
            }
        },
        mesactual() {
            console.log("mesactual123", this.mesactual);
            if (this.$refs.vuecal.isMonthView==true){
                this.totalcitas(this.anyoactual, this.mesactual);
            }
        },
    /*    id() {
            this.cargareventos();
            
        },*/
        operarioseleccionado() {
            this.cargarespecialidadoperarios();
        },
       
       operariosseleccionados(value) {
        
            if(value == ''){
                this.vistaactiva = "day";
                this.cargareventos('operselec');
            }
            //this.operariossplit = [];
              for (let i = 0; i < this.splitDays.length; i++){
                  this.splitDays[i].hide = true;
                  if (this.$props.operariosseleccionados.length < 1 && this.$props.cambios == false) { this.splitDays[i].hide = false;  }
                  for (let j = 0; j < this.$props.operariosseleccionados.length; j++){
                    //console.log("dia operario: "+this.splitDays[i].id+" Operario seleccionado:"+this.$props.operariosseleccionados[j]);
                      if (this.splitDays[i].id == this.$props.operariosseleccionados[j]) {
                          this.splitDays[i].hide = false;
                      }
                  }
              }
            for (let k = 0; k < this.splitDays.length; k++) { 
                if (this.splitDays[k].hide == false) {
                    this.operariossplit.push(this.splitDays[k]);
                }
            }
            var alguno = false;
            for (let i = 0; i < this.operariossplit.length; i++) {
                if (this.operariossplit[i].hide == false) {
                    alguno = true;
                }
            }
            if(!alguno){
                this.ocultar = true;
            }else{
                this.ocultar = false;
            }
        },
        
        operarios() {
            this.cargareventos('operarios');
        },
        fechacald() {
            
            console.log("diaactual", this.diaactual);
            console.log("mesactual", this.mesactual);
            console.log("anyoactual", this.anyoactual);
   
            this.anyoactual = String(this.fechacald.getFullYear());
            this.mesactual = this.fechacald.getMonth() + 1;
            this.diaactual = this.fechacald.getDate();
            this.selectedDate = this.fechacald;
          

           
            
        }
    }
})
</script>
<style>
.vuecal__cell-events-count{
    color: rgba(0, 0, 0, 0);
        background-color: rgba(255, 255, 0, 0);

    height: 15px;
};
.vuecal__title button {
    color: rgb(0, 0, 0);
}
.vuecal--month-view .vuecal__cell {
    height: 70px;
        margin-top: 0px !important;
}
.vuecal__today-btn {
    font-size: 0.8em;
    border-radius: 5px;
    color: rgb(0, 0, 0);
}
.vuecal__today-btn:hover {
    background-color: rgb(0, 105, 217);
    border-radius: 4px;
    color: rgb(255, 255, 255);
}
.table td, .table th {
	padding: .75rem;
	vertical-align: middle;
	border-top: 0px solid #dee2e6;
}
/*.vuecal__time-cell-line {
    padding-top: 5.5em !important;
}*/

.vuecal .vuecal__split-days-headers {
    align-items: flex-start;
}

.vuecal__time-column {
    margin-top: 5em !important;
}

.vuecal__split-days-headers {
    text-align: center;
    height: 10em !important;
}
.vuecal__title-bar{
    margin-bottom:0%;
}
.vuecal__body{
    margin-top:0%;
}
.operarioseleccionado{
    width:70%;
}
.GUARDIA{
      background:
    #fff7f0 
    repeating-linear-gradient(
      -45deg,
      rgba(255, 87, 87, 0.25),
      rgba(255, 87, 87, 0.25) 5px,
      rgba(255, 255, 255, 0) 5px,
      rgba(255, 255, 255, 0) 15px
    ) !important;
    color:rgb(216, 27, 96) !important;    
}
.OCUPADO {
    background:
        #fff7f0 
    repeating-linear-gradient(
        -45deg,
            rgba(102, 126, 231, 0.25),
            rgba(102, 126, 231, 0.25), 5px,
            rgba(255, 255, 255, 0) 5px,
            rgba(255, 255, 255, 0) 15px) 
            !important;
    color: rgb(30, 27, 216) !important;
}
.day-split-header {
    padding-bottom: 20px !important;
}
.vuecal__arrow--next {
    right: 38%;
}

.vuecal__arrow--prev {
    left: 36%;
}
.vuecal__today-btn{
    right:90%;
}
.BLOQUEADO {
    background:
        #fff7f0 repeating-linear-gradient(
            -45deg,
            rgba(71, 98, 104, 0.25),
            rgba(71, 98, 104, 0.25), 5px,
            rgba(255, 255, 255, 0) 5px,
            rgba(255, 255, 255, 0) 15px) !important;
    color: rgb(71, 98, 104) !important;
}
.VACACIONES {
    background:
        #fff7f0 repeating-linear-gradient(-45deg,
            rgba(0, 204, 0, 0.25),
            rgba(0, 204, 0, 0.25), 5px,
            rgba(255, 255, 255, 0) 5px,
            rgba(255, 255, 255, 0) 15px) !important;
    color: rgb(18, 110, 18) !important;
}
.RESERVA {
    background:rgb(253, 126, 20) !important;
}
.vuecal__cell-split{
    border: 0.5px solid;
    border-color:lightgray;
}
.vuecal__cell {
    margin-top: -23px !important;
}
.vuecal__flex .vuecal__cell-content{
    display:block;
}
.inputfecha{
    width: 100%;
}
.vuecal__event:hover {
    min-height: 50px;
}
.loading {
    position: absolute;
        background: #FFFF;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 20000;
}
.spinner {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    border: 9px solid;
    border-color: #d9e3ff;
    border-right-color: #007bff;
    animation: spinner-d3wgkg 1s infinite linear;
    position: absolute;
    z-index: 30000;
    top: 20%;
    left: 50%;
    margin-left: -50px;
    

}
.miclaseplanning:before
{border-top: 1px solid  rgb(211, 211, 211);}
.miclaseplanningdos:before{border-top: 1px solid  rgb(218, 13, 13);}

@keyframes spinner-d3wgkg {
    to {
        transform: rotate(1turn);
    }
}
</style>
