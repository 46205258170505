<template>
    <!--
    Este
    -->
    <div v-if="localOperarios.operario">
        <div class="card"  v-for="operario in Object.entries(localOperarios.operario)" :key="operario[0]">
            <div class="card-header bg-gray">
                <h3 @click="mostrarNotas(operario[1], operario[0])" style="cursor: pointer;" :title="operario[1].Cliente.trabajos[0].datos.nombre_operario" class="card-title" v-if="operario[1].Cliente"><i class="fas fa-list mr-2"></i>{{
                    $t('general.operario') }}
                    {{operario[1].Cliente.trabajos[0].datos.nombre_operario.slice(0,20)}} </h3>
                <h3 @click="mostrarNotas(operario[1], operario[0])" style="cursor: pointer;" :title="operario[1].Compañia.trabajos[0].datos.nombre_operario" class="card-title" v-else-if="operario[1].Compañia"><i class="fas fa-list mr-2"></i>{{
                    $t('general.operario') }} {{operario[1].Compañia.trabajos[0].datos.nombre_operario.slice(0,20)}}</h3>
                <h3 @click="mostrarNotas(operario[1], operario[0])" style="cursor: pointer;" :title="operario[1]._.trabajos[0].datos.nombre_operario" class="card-title" v-else-if="operario[1]._"><i class="fas fa-list mr-2"></i>{{
                    $t('general.operario') }}
                    {{ operario[1]._.trabajos[0].datos.nombre_operario.slice(0,20) }}</h3>
                <h3 @click="mostrarNotas(operario[1], operario[0])" style="cursor: pointer;" :title="operario[1].operario.trabajos[0].datos.nombre_operario" class="card-title" v-else-if="operario[1].operario"><i class="fas fa-list mr-2"></i>{{
                    $t('general.operario') }} {{ operario[1].operario.trabajos[0].datos.nombre_operario.slice(0,20) }}</h3>
                <div class="card-tools" v-if="operario[1].totales">
                    <button type="button" class="btn btn-tool" title="Subsanar todos operario" style="color:white;"
                        @click="subsanarTrabajos(operario[1])"><i class="fas fa-tools"></i>&nbsp;Subsanar</button>
                    <button type="button" class="btn btn-tool" title="Revisar todos operario" style="color:white;"
                        @click="revisarTrabajos(operario[1])"><i class="fas fa-check-double"></i>&nbsp;Revisar</button>
                    <button type="button" class="btn btn-tool" title="Marcar facturar operario" style="color:white;"
                        @click="facturarTrabajos(operario[1])"><i class="fas fa-file-invoice-dollar"></i>&nbsp;Marcar
                        facturar</button>
                    <button type="button" class="btn btn-tool" title="Eliminar todos los trabajos del operario"
                        style="color:white;" @click="eliminarTrabajos(operario[1])"><i
                            class="fas fa-times"></i>&nbsp;Eliminar</button>
                    <button type="button" class="btn btn-tool"
                        title="Eliminar todos los trabajos del operario al cliente" style="color:white;"
                        @click="eliminarTrabajosCliente(operario[1])"><i class="far fa-times-circle"></i>&nbsp;Eliminar
                        cliente</button>
                    <button type="button" class="btn btn-tool"
                        title="Eliminar todos los trabajos del operario al operario" style="color:white;"
                        @click="eliminarTrabajosOperario(operario[1])"><i class="fas fa-times-circle"></i>&nbsp;Eliminar
                        operario</button>
                    &nbsp;
                    &nbsp;
                    <span :title="$t('general.total') + ' ' + $t('general.a cliente')" class="badge bg-success mr-2">{{
                        operario[1].totales.cliente}}&euro;</span>
                    <span :title="$t('general.total') + ' ' + $t('general.a operario')" class="badge bg-danger mr-2">
                        {{ formattedOperarioTotal(operario[1].totales.operario.base,
                        Math.round(operario[1].totales.operario.base-operario[1].totales.operario.contado)) }}</span>
                    <span :title="$t('general.rentabilidad')"
                        class="badge bg-info  mr-2">{{operario[1].totales.beneficio.porcentaje}}%</span>
                    &nbsp;
                    <button type="button" class="btn btn-tool" style="color:white"
                        @click="toggleImportarPlantilla(getKeyByValue(localOperarios.operario,operario[1]))"
                        title="Importar plantilla">
                        <i class="fas fa-plus-square mr-1"></i> Importar plantilla
                    </button>
                    <button type="button" class="btn btn-tool" style="color:white"
                        @click="toggleImportarPresupuesto(getKeyByValue(localOperarios.operario,operario[1]))"
                        title="Importar trabajo presupuesto">
                        <i class="fas fa-plus-square mr-1"></i> Importar
                    </button>
                    <button style="color: white;/*! size: ; */font-size: larger;" @click="mostrarNotas(operario[1], operario[0])" title="Mostrar notas" type="button"
                        class="btn btn-tool">
                        <i class="far fa-sticky-note"></i>
                    </button>
                    <button type="button" class="btn btn-tool" data-card-widget="maximize">
                        <i class="fas fa-expand"></i>
                    </button>
                    <button type="button" class="btn btn-tool" data-card-widget="collapse">
                        <i class="fas fa-minus"></i>
                    </button>
                </div>
            </div>
            <div class="card-body">
                <div v-if="operario[1].mostrarNotas">
                    <div v-if="operario[1].notas.length==0">
                        <label for="">No hay notas para este operario.</label>
                    </div>
                    <div v-if="operario[1].notas.length>0">
                        <div v-for="nota in operario[1].notas" :key="nota" class="direct-chat-msg">
                            <div class="direct-chat-infos clearfix">
                                <!--<span class="direct-chat-name float-left">{{ mensaje.usuario_mensaje }}</span>-->
                                <span class="direct-chat-timestamp float-right">{{ nota.fecha }}</span>
                            </div>
                            <img class="direct-chat-img" src="https://plataforma.apuyen.app/img/trabajadora.jpg"
                                alt="message user image">
                            <div class="direct-chat-text" style="display: grid;">
                                <div style="display: grid; grid-template-columns: 1fr 100px 1fr; gap: 10px;">
                                    <p v-html="nota.tipo_cliente + '<br>' + nota.texto"></p>
                                    <div class="custom-control custom-switch">
                                        <input type="checkbox" @change="cambiarEstadoNota(nota.id_nota, nota.revisado)"
                                            :id="nota.id_nota" v-model="nota.revisado" class="custom-control-input">
                                        <label class="custom-control-label" :for="nota.id_nota">Revisada</label>
                                    </div>
                                    <div v-if="nota.archivos.length >0" style="padding: 11px;">
                                        <div class="card card-default">
                                            <div class="card-header ch">
                                                <h4 class="card-title">{{$t('general.archivos')}}</h4>
                                            </div>
                                            <div class="card-body" style="padding-bottom: 15px;">
                                                <div class="row" style="justify-content: space-around;row-gap: 10px;">
                                                    <Galleria v-model:activeIndex="nota.activeIndex"
                                                        v-model:visible="nota.displayCustom"
                                                        :responsiveOptions="responsiveOptions" :value="nota.archivos"
                                                        :numVisible="7" containerStyle="max-width: 850px"
                                                        :circular="true" :fullScreen="true" :showItemNavigators="true"
                                                        :showThumbnails="false">
                                                        <template #item="slotProps">
                                                            <img v-if="urlglobal != 'null.pwgs.app'" class="imgcarr"
                                                                :src='slotProps.item.url_completa_descarga' preview
                                                                :alt="slotProps.item.descripcion" />
                                                            <img v-if="urlglobal == 'null.pwgs.app'" class="imgcarr"
                                                                :src='"https://desarrollo.pwgs.app/documentos.php?documento="  + slotProps.item.documento + "&servicio=" +id'
                                                                preview :alt="slotProps.item.descripcion" />
                                                        </template>
                                                        <template #thumbnail="slotProps">
                                                            <img v-if="urlglobal != 'null.pwgs.app'" class="imgcarr"
                                                                :src='slotProps.item.url_completa_descarga' preview
                                                                :alt="slotProps.item.descripcion" />
                                                            <img v-if="urlglobal == 'null.pwgs.app'" class="imgcarr"
                                                                :src='"https://desarrollo.pwgs.app/documentos.php?documento="  + slotProps.item.documento + "&servicio=" +id'
                                                                preview :alt="slotProps.item.descripcion" />
                                                        </template>
                                                    </Galleria>
                                                    <div v-for="(archivos, index) in nota.archivos" :key="archivos">
                                                        <a download :id="archivos.id_documento"
                                                            :href='archivos.url_completa_descarga'><i
                                                                class="fas fa-download"></i></a>
                                                        <i title="Mostrar descripción" @click="descripcionarchivo = archivos.descripcion; toggle($event)"
                                                            style="margin-left: 20%;font-size: 1.2rem;color: #007bff;cursor: pointer;"
                                                            class="fas fa-info-circle"></i>
                                                        <div style="display: grid" v-if="archivos.image==''">
                                                            <i class="fas fa-file fa-8x"></i>
                                                        </div>
                                                        <div v-else-if="archivos.propiedad_cliente!=0">
                                                            <div class="image-container">
                                                                <img style="width: 100px; height: 127px;"
                                                                    @click="imageClick(nota, index)"
                                                                    v-if="urlglobal != 'null.pwgs.app'"
                                                                    :src='archivos.url_completa_descarga' width="100"
                                                                    preview :alt="archivos.descripcion" />
                                                                <img style="width: 100px; height: 127px;"
                                                                    @click="imageClick(nota, index)"
                                                                    v-if="urlglobal == 'null.pwgs.app'"
                                                                    :src='archivos.url_completa_descarga' width="100"
                                                                    preview :alt="archivos.descripcion" />
                                                                <!--  <div style="width: 350px;" class="image-zoom-overlay">
                                                                    <img style="width: 300px; height: 300px;"
                                                                        @click="imageClick(nota, index)"
                                                                        v-if="urlglobal != 'null.pwgs.app'"
                                                                        :src='archivos.url_completa_descarga'
                                                                        :alt="archivos.descripcion" />
                                                                    <img style="width: 300px; height: 300px;"
                                                                        @click="imageClick(nota, index)"
                                                                        v-if="urlglobal == 'null.pwgs.app'"
                                                                        :src='archivos.url_completa_descarga'
                                                                        :alt="archivos.descripcion" />
                                                                </div>-->
                                                            </div>
                                                        </div>
                                                        <div v-else>
                                                            <div class="image-container">
                                                                <img style="width: 100px; height: 127px;"
                                                                    @click="imageClick(nota, index)"
                                                                    v-if="urlglobal != 'null.pwgs.app'"
                                                                    :src='archivos.url_completa_descarga' width="100"
                                                                    preview :alt="archivos.descripcion" />
                                                                <img style="width: 100px; height: 127px;"
                                                                    @click="imageClick(nota, index)"
                                                                    v-if="urlglobal == 'null.pwgs.app'"
                                                                    :src='"https://desarrollo.pwgs.app/documentos.php?documento=" + archivos.documento + "&servicio=" +id'
                                                                    width="100" preview :alt="archivos.descripcion" />
                                                                <!--   <div style="width: 350px;" class="image-zoom-overlay">
                                                                    <img style="width: 300px; height: 300px;"
                                                                        @click="imageClick(nota, index)"
                                                                        v-if="urlglobal != 'null.pwgs.app'"
                                                                        :src='archivos.url_completa_descarga'
                                                                        :alt="archivos.descripcion" />
                                                                    <img style="width: 300px; height: 300px;"
                                                                        @click="imageClick(nota, index)"
                                                                        v-if="urlglobal == 'null.pwgs.app'"
                                                                        :src='"https://desarrollo.pwgs.app/documentos.php?documento=" + archivos.documento + "&servicio=" +id'
                                                                        :alt="archivos.descripcion" />
                                                                </div>-->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="nota.alarmas">
                                    <div v-for="alarma in nota.alarmas" :key="alarma">
                                        <p>{{ $t('general.alarma') }}:</p>
                                        <p>{{ $t('general.usuario') }}:{{ nombre_usuario_alarma }} {{
                                            $t('general.remitente2') }}:{{ nombre_remitente_alarma }}</p>
                                        <p>{{ alarma.fecha_alarma }}{{ hora_alarma }}</p>
                                    </div>
                                    <button class="btn btn-default">{{$t('general.editaralarma')}}</button>
                                </div>
                                <!--<input type="checkbox" class="checknotas" v-model="nota.revisado"
                                    @click="cambiarEstadoNota(nota.id_nota, nota.revisado)" />-->
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="showComponent && servicio.estado == 'Activo'">



                    <trabajosrealizadonuevo :id="id" :cambio="cambio"
                        :operario="getKeyByValue(localOperarios.operario,operario[1])" :servicio="servicio" />
                </div>
                <div v-if="mostrarImportarPresupuesto[getKeyByValue(localOperarios.operario,operario[1])]">
                    <TrabajosImportarPresupuesto :id="id" :operario="getKeyByValue(localOperarios.operario,operario[1])"
                        :servicio="servicio"
                        @trabajos-importados="ocultarImportarPresupuesto(getKeyByValue(localOperarios.operario,operario[1]))" />
                </div>
                <div v-if="mostrarImportarPlantilla[getKeyByValue(localOperarios.operario,operario[1])]">
                    <TrabajosImportarPlantilla :id="id" :operario="getKeyByValue(localOperarios.operario,operario[1])"
                        :servicio="servicio"
                        @plantillas-importadas="ocultarImportarPlantilla(getKeyByValue(localOperarios.operario,operario[1]))" />
                </div>
                <div v-if="operario[1].Cliente" v-show="!ocultarcliente">
                    <trabajosrealizadoconcepto ref="conc1" @cambio="cambiado"
                        v-for="totales in operario[1].Cliente.trabajos" :key="totales" :totales="totales" :id="id"
                        :servicio="servicio" />
                </div>
                <div v-if="operario[1].Compañia" v-show="!ocultarcliente">
                    <trabajosrealizadoconcepto ref="conc2" @cambio="cambiado"
                        v-for="totales in operario[1].Compañia.trabajos" :key="totales" :totales="totales" :id="id"
                        :servicio="servicio" />
                </div>
                <!-- Renderizado de Perjudicados -->
                <div v-for="(perjudicado, index) in getPerjudicados(operario[1])" :key="index" v-show="!ocultarcliente">
                    <trabajosrealizadoconcepto ref="conc3" @cambio="cambiado" v-for="totales in perjudicado.trabajos"
                        :key="totales" :totales="totales" :id="id" :servicio="servicio" />
                </div>

                <div v-if="operario[1]._" v-show="!ocultarcliente">
                    <div v-for="totales in operario[1]._.trabajos" :key="totales.id" class="position-relative">
                        <trabajosrealizadoconcepto ref="conc4" @cambio="cambiado" :totales="totales" :id="id"
                            :servicio="servicio" />
                    </div>
                </div>

                <hr v-if="operario[1].operario" v-show="!ocultaroperario" style="border:2px dashed gray">

                <div v-if="operario[1].operario" v-show="!ocultaroperario">
                    <trabajosrealizadoconceptoOperario ref="conc5" @cambio="cambiado"
                        :tipooperario="operario[1].operario.trabajos[0].datos.tipo_operario"
                        v-for="totales in operario[1].operario.trabajos" :key="totales" :totales="totales" :id="id"
                        :servicio="servicio" />
                </div><!--PROGRESSBAR-->
                <div v-if="detalletrabajos[operario[0]]" class="progress-group" style="margin-bottom: 4px;">
                    <span class="progress-text" style="font-size: 10px;"
                        :title="detalletrabajos[operario[0]].anterior.codigo.title">{{
                        detalletrabajos[operario[0]].anterior.codigo.valor }}
                        &nbsp;</span>
                    <span class="progress-number" style="font-size: 10px; float: right"
                        :title="detalletrabajos[operario[0]].actual.codigo.title"><b>{{
                            detalletrabajos[operario[0]].actual.codigo.valor }}</b></span>
                    <div class="progress">
                        <div :class="'progress-bar  progress-bar-striped ' + detalletrabajos[operario[0]].color"
                            title="Tiempo teórico desplazamiento" role="progressbar"
                            :style="'min-width: ' + Math.round(detalletrabajos[operario[0]].porcentaje_visita) +'%; display: block !important; width:' + (100-Math.round(detalletrabajos[operario[0]].porcentaje_visita))+'%'"
                            aria-valuenow="15" aria-valuemin="0" aria-valuemax="100">
                            <span :title="detalletrabajos[operario[0]].anterior.hora_finaliza.title"
                                style="float:left; font-size: 10px; margin:3px; color: #ffffff; font-weight: bold;">
                                {{ detalletrabajos[operario[0]].anterior.hora_finaliza.valor}}</span>
                            <b v-if="detalletrabajos[operario[0]].anterior.km" style="position:absolute;margin-top:7px"
                                :title="detalletrabajos[operario[0]].anterior.duracion.title">{{
                                detalletrabajos[operario[0]].anterior.duracion.horas }}:{{
                                detalletrabajos[operario[0]].anterior.duracion.minutos }}
                                ({{ detalletrabajos[operario[0]].anterior.km }}km)</b>
                        </div>
                        <div class="progress-bar" role="progressbar"
                            :title="detalletrabajos[operario[0]].actual.duracion.title"
                            :style="'background-color: #b5b3b3; min-width: 28%; display: block !important; width:' + Math.round(detalletrabajos[operario[0]].porcentaje_visita) +'%'"
                            :aria-valuenow="Math.round(detalletrabajos[operario[0]].porcentaje_visita)"
                            aria-valuemin="0" aria-valuemax="100">
                            <span :title="detalletrabajos[operario[0]].actual.hora.title"
                                style="float:left; font-size: 10px; margin:3px; color: #ffffff; font-weight: bold;">
                                {{ detalletrabajos[operario[0]].actual.hora.valor}}</span>
                            <b style="position:absolute;margin-top:7px">{{
                                detalletrabajos[operario[0]].actual.duracion.horas }}:{{
                                detalletrabajos[operario[0]].actual.duracion.minutos}}</b>
                            <span :title="detalletrabajos[operario[0]].actual.hora_finaliza.title"
                                style="float:right; font-size: 10px; margin:3px; color: #ffffff; font-weight: bold;">
                                {{ detalletrabajos[operario[0]].actual.hora_finaliza.valor}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <trabajosrealizadosoperariofooter ref="foot" :datos="operario[1]" :actualizar="actualizartot" />
            </div>
        </div>
        <OverlayPanel ref="opdescripcion">
            <div class="flex flex-column gap-3 w-25rem">
                    <b>Descripción del archivo</b>
                    <p v-html="descripcionarchivo"></p>
            </div>
        </OverlayPanel>
    </div>
</template>
<script>
import Galleria from 'primevue/galleria';
import { PwgsApi } from '../../../../services/PwgsApi';
import trabajosrealizadosoperariofooter from './TrabajosRealizadosOperarioFooter.vue';
import trabajosrealizadoconcepto from './TrabajoRealizadoConcepto.vue';
import trabajosrealizadoconceptoOperario from './TrabajoRealizadoConceptoOperario.vue';
import trabajosrealizadonuevo from './TrabajoRealizadoNuevo.vue';
import TrabajosImportarPresupuesto from './TrabajosImportarPresupuesto.vue';
import TrabajosImportarPlantilla from './TrabajosImportarPlantilla.vue';
import OverlayPanel from 'primevue/overlaypanel';

export default ({
    props:[
        'id',
        'servicio',
        'recargar',
        'ocultarcliente',
        'ocultaroperario',
        'totalrent',
        'totaloperario',
        'totalcliente',
        'mostrarfacturar',
        'operariosingular',
        'operarios',
        'actualizartotales'
    ],
    setup() {
        return;
    },
    components: {
        trabajosrealizadosoperariofooter,
        trabajosrealizadoconcepto,
        trabajosrealizadoconceptoOperario,
        trabajosrealizadonuevo,
        TrabajosImportarPresupuesto,
        TrabajosImportarPlantilla,
        Galleria,
        OverlayPanel,
    },
    data() {
        return {
            cambio:false,
            actualizartot:false,
            showComponent: true,
            detalletrabajos:'',
            mostrarImportarPresupuesto: {},
            mostrarImportarPlantilla: {}, 
//          operarios: [],
            totalescliente: '',
            totalesoperario: '',
            totalesrentabilidad: '',
            suma: [],
            cargar: false,
            localOperarios: {},
            urlglobal: '',
            trabajossub: false,
            trabajosrev: false,
            trabajosfac:false,
            descripcionarchivo:'',
            responsiveOptions: [
                {
                    breakpoint: '1024px',
                    numVisible: 5
                },
                {
                    breakpoint: '768px',
                    numVisible: 3
                },
                {
                    breakpoint: '560px',
                    numVisible: 1
                }
            ],
        };
    },
    methods: {
        toggle(event){
            this.$refs.opdescripcion.toggle(event);
        },
        cambiarMarcadoTrue(){
            if(this.$refs.conc1){
                for(let ref of this.$refs.conc1){
                    ref.cambiarMarcadoTrue();
                }
            }
            if(this.$refs.conc2){
                for(let ref of this.$refs.conc2){
                    ref.cambiarMarcadoTrue();
                }
            }
            if(this.$refs.conc3){
                for(let ref of this.$refs.conc3){
                    ref.cambiarMarcadoTrue();
                }
            }
            if(this.$refs.conc4){
                for(let ref of this.$refs.conc4){
                    ref.cambiarMarcadoTrue();
                }
            }
            if(this.$refs.conc5){
                for(let ref of this.$refs.conc5){
                    ref.cambiarMarcadoTrue();
                }
            }
        },
        cambiarMarcadoFalse(){
            if(this.$refs.conc1){
                for(let ref of this.$refs.conc1){
                    ref.cambiarMarcadoFalse();
                }
            }
            if(this.$refs.conc2){
                for(let ref of this.$refs.conc2){
                    ref.cambiarMarcadoFalse();
                }
            }
            if(this.$refs.conc3){
                for(let ref of this.$refs.conc3){
                    ref.cambiarMarcadoFalse();
                }
            }
            if(this.$refs.conc4){
                for(let ref of this.$refs.conc4){
                    ref.cambiarMarcadoFalse();
                }
            }
            if(this.$refs.conc5){
                for(let ref of this.$refs.conc5){
                    ref.cambiarMarcadoFalse();
                }
            }
        },
        cambiado(val){
            console.log('cambio', val);
            this.cambio=val;
        },
        mostrardatos(dato){
            console.log("datos", dato);
        },
        actualiztotales(id, resultados){
            console.log('llega1', id, resultados);
            var indice = Object.keys(resultados.operario).indexOf(id);
            console.log('indice',indice);            
            this.$refs.foot[indice].actualizatotales(id, resultados);
            console.log('estae',this.localOperarios, resultados, indice, id);
            console.log('estae2', this.localOperarios.operario[id], resultados.operario[id])
            this.localOperarios.operario[id] = resultados.operario[id];
        },
        imageClick(nota, index) {
            nota.activeIndex = index;
            nota.displayCustom = true;
        },
        toggleComponent() {
            this.showComponent = !this.showComponent;
        },
        async subsanarTodos(){
            for(let operario of Object.values(this.localOperarios.operario)){
                const api = new PwgsApi();
                console.log('opererer', operario);
                var trabajos = [];
                for(let datos of Object.entries(operario)){
                    console.log(datos[0]);
                    if(datos[0] !='totales'){
                        if(datos[1].trabajos){
                            for (let i = 0; i < datos[1].trabajos.length; i++){
                                if(datos[1].trabajos[i].datos.marcado)
                                trabajos.push(datos[1].trabajos[i].datos.idtrabajo_servicio);
                            }
                        }
                    }
                }
                console.log('trabajosasubsanar', trabajos);
                if(trabajos.length > 0){
                    try{
                        await api.put('trabajos/' + this.$props.id + '/subsanar', {ids_trabajos:trabajos});
                        this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Trabajos guardados para subsanar', life: 2000 });
                        this.cargardatos;
                        setTimeout(() => {
                            console.log('finalllyy');
                            for(let trabajo of trabajos){
                                if(this.$refs.conc1){
                                    for(let ref of this.$refs.conc1){
                                        ref.guardarsubsanado(trabajo);
                                    }
                                }
                                if(this.$refs.conc2){
                                    for(let ref of this.$refs.conc2){
                                        ref.guardarsubsanado(trabajo);
                                    }
                                }
                                if(this.$refs.conc3){
                                    for(let ref of this.$refs.conc3){
                                        ref.guardarsubsanado(trabajo);
                                    }
                                }
                                if(this.$refs.conc4){
                                    for(let ref of this.$refs.conc4){
                                        ref.guardarsubsanado(trabajo);
                                    }
                                }
                                if(this.$refs.conc5){
                                    for(let ref of this.$refs.conc5){
                                        ref.guardarsubsanado(trabajo);
                                    }
                                }
                            }
                        }, 1000);
                    }
                    catch (error) {
                            if (error == "Error: Token no válido") {
                                api.get('logout');
                                localStorage.removeItem('token');
                                this.$router.push('/login');
                            }
                            this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
                    }
                }
            }
        },
        async subsanarTrabajos(operario) {
            const api = new PwgsApi();
            console.log('opererer', operario);
            var trabajos = [];
            for(let datos of Object.entries(operario)){
                console.log(datos[0]);
                if(datos[0] !='totales'){
                    for (let i = 0; i < datos[1].trabajos.length; i++){                        
                        trabajos.push(datos[1].trabajos[i].datos.idtrabajo_servicio);
                    }
                }
            }
            console.log('trabajosasubsanar', trabajos);
            if(trabajos.length > 0){
                try{
                    if(window.confirm('¿Quiere subsanar todos los trabajos del operario?')){
                        await api.put('trabajos/' + this.$props.id + '/subsanar', {ids_trabajos:trabajos});
                        this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Trabajos guardados para subsanar', life: 2000 });
                        this.cargardatos;
                        setTimeout(() => {
                            console.log('finalllyy');
                            for(let trabajo of trabajos){
                                if(this.$refs.conc1){
                                    for(let ref of this.$refs.conc1){
                                        ref.guardarsubsanado(trabajo);
                                    }
                                }
                                if(this.$refs.conc2){
                                    for(let ref of this.$refs.conc2){
                                        ref.guardarsubsanado(trabajo);
                                    }
                                }
                                if(this.$refs.conc3){
                                    for(let ref of this.$refs.conc3){
                                        ref.guardarsubsanado(trabajo);
                                    }
                                }
                                if(this.$refs.conc4){
                                    for(let ref of this.$refs.conc4){
                                        ref.guardarsubsanado(trabajo);
                                    }
                                }
                                if(this.$refs.conc5){
                                    for(let ref of this.$refs.conc5){
                                        ref.guardarsubsanado(trabajo);
                                    }
                                }
                            }
                        }, 1000);
                    }
                }
                 catch (error) {
                        if (error == "Error: Token no válido") {
                            api.get('logout');
                            localStorage.removeItem('token');
                            this.$router.push('/login');
                        }
                        this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
                }
            }
            
        },
        async cargarDetalle() {
            const api = new PwgsApi();
            this.detalletrabajos = await api.get('servicios/' + this.$props.id + '/tiempos-operarios');
            console.log("detalletiempos", this.detalletrabajos);

            
        },      
        async facturarTrabajos(operario) {
            let trabajos = [];
            for(let datos of Object.entries(operario)){
                console.log(datos[0]);
                if(datos[0] !='totales' && datos[0] !='operario'){
                    for (let i = 0; i < datos[1].trabajos.length; i++){                        
                        trabajos.push(datos[1].trabajos[i].datos.idtrabajo_servicio);
                    }
                }
            }
            console.log('trabajos', trabajos);
            let subidadatos = { ids_trabajos: trabajos };
            const api = new PwgsApi();
            try{
                await api.put('trabajos/' + this.$props.id + '/facturar-varios', subidadatos);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Trabajos guardados para facturar', life: 2000 });
                this.cargardatos;
                for(let trabajo of trabajos){
                    if(this.$refs.conc1){
                        for(let ref of this.$refs.conc1){
                            ref.guardarfacturar(trabajo);
                        }
                    }
                    if(this.$refs.conc2){
                        for(let ref of this.$refs.conc2){
                            ref.guardarfacturar(trabajo);
                        }
                    }
                    if(this.$refs.conc3){
                        for(let ref of this.$refs.conc3){
                            ref.guardarfacturar(trabajo);
                        }
                    }
                    if(this.$refs.conc4){
                        for(let ref of this.$refs.conc4){
                            ref.guardarfacturar(trabajo);
                        }
                    }
                }
            }
            catch (error) {
                if (error == "Error: Token no válido") {
                    api.get('logout');
                    localStorage.removeItem('token');
                    this.$router.push('/login');
                }
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
            
        },
        async revisarTrabajos(operario) {            
            let trabajos = [];
            for(let datos of Object.entries(operario)){
                console.log(datos[0]);
                if(datos[0] !='totales'){
                    for (let i = 0; i < datos[1].trabajos.length; i++){
                        trabajos.push(datos[1].trabajos[i].datos.idtrabajo_servicio);
                    }
                }
            }
            let subidadatos = { ids_trabajos: trabajos };
            const api = new PwgsApi();
            try{
                await api.put('trabajos/' + this.$props.id + '/revisar', subidadatos);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Trabajos guardados para revisar', life: 2000 });
                this.cargardatos;
                for(let trabajo of trabajos){
                    if(this.$refs.conc1){
                        for(let ref of this.$refs.conc1){
                            ref.guardarrevisado(trabajo);
                        }
                    }
                    if(this.$refs.conc2){
                        for(let ref of this.$refs.conc2){
                            ref.guardarrevisado(trabajo);
                        }
                    }
                    if(this.$refs.conc3){
                        for(let ref of this.$refs.conc3){
                            ref.guardarrevisado(trabajo);
                        }
                    }
                    if(this.$refs.conc4){
                        for(let ref of this.$refs.conc4){
                            ref.guardarrevisado(trabajo);
                        }
                    }
                    if(this.$refs.conc5){
                        for(let ref of this.$refs.conc5){
                            ref.guardarrevisado(trabajo);
                        }
                    }
                }
            }
            catch (error) {
                if (error == "Error: Token no válido") {
                    api.get('logout');
                    localStorage.removeItem('token');
                    this.$router.push('/login');
                }
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }            
        },
        toggleImportarPresupuesto(operarioId) {
            //this.mostrarImportarPresupuesto = !this.mostrarImportarPresupuesto;
            if (this.mostrarImportarPresupuesto[operarioId]) {
                this.mostrarImportarPresupuesto[operarioId] = !this.mostrarImportarPresupuesto[operarioId];
            } else {
                this.mostrarImportarPresupuesto[operarioId] = true;
            }
        },
        toggleImportarPlantilla(operarioId) {
            if (this.mostrarImportarPlantilla[operarioId]) {
                this.mostrarImportarPlantilla[operarioId] = !this.mostrarImportarPlantilla[operarioId];
            } else {
                this.mostrarImportarPlantilla[operarioId] = true;
            }
        },
        ocultarImportarPresupuesto(operarioId) {
            //this.mostrarImportarPresupuesto = false; // Ocultar el callout de importar
            this.mostrarImportarPresupuesto[operarioId] = false;
            this.$toast.add({ severity: 'success', summary: this.$t('general.guardado'), detail: this.$t('general.datosguardadoscorrectamente'), life: 2000 });
            this.cargardatos(); // Volver a cargar los datos del componente padre
        },
        ocultarImportarPlantilla(operarioId) {
            //this.mostrarImportarPlantilla = false; // Ocultar el callout de importar
            this.mostrarImportarPlantilla[operarioId] = false;
            this.$toast.add({ severity: 'success', summary: this.$t('general.guardado'), detail: this.$t('general.datosguardadoscorrectamente'), life: 2000 });
            this.cargardatos(); // Volver a cargar los datos del componente padre
        },
        formattedOperarioTotal(base, contado) {
            if(base){
            let baseFormatted = `${base}€`;
            if (contado && contado !== 0) {
                baseFormatted += ` (A operario ${contado}€)`;
            }
            return baseFormatted;
            }
        },

        async desactivaralarma(idnota, idalarma) {
            const api = new PwgsApi();
            let subidadatos = { id_alarma: idalarma};
            await api.put('servicios/' + this.$props.id + '/notas/' + idnota, subidadatos);
            this.motivo = '';
            this.cargarnotas();
        },
        async cambiarEstadoNota(id_nota, estadoActual) {
            // Aquí puedes manejar la lógica diferente para marcar y desmarcar
            if (!estadoActual) {
                this.procesarNotaRevisada(id_nota);
            } else {
                this.procesarNotaNoRevisada(id_nota);
            }
        },

        async procesarNotaNoRevisada(idnota) {
            const api = new PwgsApi();
            let body = {tipo: "trabajos_no_revisados", ids_avisos: [idnota], estado: 1};
            await api.put('/avisos/revisar-avisos', body);
        },
        async procesarNotaRevisada(idnota) {
            const api = new PwgsApi();
            let body = {tipo: "trabajos_no_revisados", ids_avisos: [idnota], estado: 0};
            await api.put('/avisos/revisar-avisos', body);
        },
        async eliminarTrabajos(operario){
            let trabajos = [];
            for(let datos of Object.entries(operario)){
                console.log(datos[0]);
                if(datos[0] !='totales'){
                    for (let i = 0; i < datos[1].trabajos.length; i++){
                        trabajos.push(datos[1].trabajos[i].datos.idtrabajo_servicio);
                    }
                }
            }
            if(window.confirm('¿Quiere eliminar todos los trabajos del operario?')){
                console.log('entraeliminar')
                for(let trabajo of trabajos){
                    console.log('entraeliminarportrabajo', trabajo)
                    if(this.$refs.conc1){
                        for(let ref of this.$refs.conc1){
                            ref.eliminartrabajosinconf(trabajo);
                        }
                    }
                    if(this.$refs.conc2){
                        for(let ref of this.$refs.conc2){
                            ref.eliminartrabajosinconf(trabajo);
                        }
                    }
                    if(this.$refs.conc3){
                        for(let ref of this.$refs.conc3){
                            ref.eliminartrabajosinconf(trabajo);
                        }
                    }
                    if(this.$refs.conc4){
                        for(let ref of this.$refs.conc4){
                            ref.eliminartrabajosinconf(trabajo);
                        }
                    }
                    if(this.$refs.conc5){
                        for(let ref of this.$refs.conc5){
                            ref.eliminartrabajosinconf(trabajo);
                        }
                    }
                }  
            }                      
        },
        async eliminarTrabajosCliente(operario){
            let trabajos = [];
            console.log('oper',operario);
            for(let datos of Object.entries(operario)){
                console.log(datos[0]);
                if(datos[0] != 'operario' && datos[0] !='totales'){
                    for (let i = 0; i < datos[1].trabajos.length; i++){
                        trabajos.push(datos[1].trabajos[i].datos.idtrabajo_servicio);
                    }
                }
            }
            if(window.confirm('¿Quiere eliminar todos los trabajos a clientes del operario?')){
                console.log('entraeliminar')
                for(let trabajo of trabajos){
                    console.log('entraeliminarportrabajo', trabajo)
                    if(this.$refs.conc1){
                        for(let ref of this.$refs.conc1){
                            ref.eliminartrabajosinconf(trabajo);
                        }
                    }
                    if(this.$refs.conc2){
                        for(let ref of this.$refs.conc2){
                            ref.eliminartrabajosinconf(trabajo);
                        }
                    }
                    if(this.$refs.conc3){
                        for(let ref of this.$refs.conc3){
                            ref.eliminartrabajosinconf(trabajo);
                        }
                    }
                    if(this.$refs.conc4){
                        for(let ref of this.$refs.conc4){
                            ref.eliminartrabajosinconf(trabajo);
                        }
                    }
                    if(this.$refs.conc5){
                        for(let ref of this.$refs.conc5){
                            ref.eliminartrabajosinconf(trabajo);
                        }
                    }
                }  
            }                 
        },
        async eliminarTrabajosOperario(operario){
            let trabajos = [];
            console.log('oper',operario);
            for(let datos of Object.entries(operario)){
                console.log(datos[0]);
                if(datos[0] == 'operario'){
                    for (let i = 0; i < datos[1].trabajos.length; i++){
                        trabajos.push(datos[1].trabajos[i].datos.idtrabajo_servicio);
                    }
                }
            }
            if(window.confirm('¿Quiere eliminar todos los trabajos a operarios del operario?')){
                console.log('entraeliminar')
                for(let trabajo of trabajos){
                    console.log('entraeliminarportrabajo', trabajo)
                    if(this.$refs.conc1){
                        for(let ref of this.$refs.conc1){
                            ref.eliminartrabajosinconf(trabajo);
                        }
                    }
                    if(this.$refs.conc2){
                        for(let ref of this.$refs.conc2){
                            ref.eliminartrabajosinconf(trabajo);
                        }
                    }
                    if(this.$refs.conc3){
                        for(let ref of this.$refs.conc3){
                            ref.eliminartrabajosinconf(trabajo);
                        }
                    }
                    if(this.$refs.conc4){
                        for(let ref of this.$refs.conc4){
                            ref.eliminartrabajosinconf(trabajo);
                        }
                    }
                    if(this.$refs.conc5){
                        for(let ref of this.$refs.conc5){
                            ref.eliminartrabajosinconf(trabajo);
                        }
                    }
                }  
            }                 
        },
        async cargardatos() {
            //GET  modulos/pwgsapi/index.php/trabajos/:id_servicio/trabajos-servicio
            const api = new PwgsApi();
            this.localOperarios = await api.get('trabajos/' + this.id + '/trabajos-servicio');            
            if (this.operariosingular > 0) {
                // Filtramos los operarios si 'operariosingular' está definido y es mayor que 0
                for (let operario in this.localOperarios.operario) {
                    if (operario != this.operariosingular) {
                        delete this.localOperarios.operario[operario];       
                    }                    
                }
            }
            console.log('localoperarios', this.localOperarios);
            // Calculamos la rentabilidad total
            this.totalesrentabilidad = this.totalcliente - this.totaloperario;
            this.totalesrentabilidad = Math.round((this.totalesrentabilidad * 100).toFixed(2) / this.totalcliente) + "%";
        },
        async cargarNotas(operario, idop){
            if(!operario.notas){
                console.log('entracargarnotas', operario);
                const api = new PwgsApi();
                              
                    const nts = await api.get('/servicios/'+this.id+'/notas?id_operario='+idop);
                    console.log('ntss', nts);
                    var notas = nts;
                    if(notas){
                        operario.notas = notas.map(nota => {
                            return this.comprobarRevisado(nota);
                        });
                        for(let nota of operario.notas){
                            nota.displayCustom = false;
                            nota.activeIndex = 0;
                        }
                    }else{
                        operario.notas = [];
                    }
                    operario.mostrarNotas = false;
                    console.log('operasasa', operario);
               
                }
                operario.mostrarNotas = !operario.mostrarNotas;
        },

        comprobarRevisado(nota) {
            return {
                ...nota,
                revisado: nota.realizado_operario && nota.realizado_operario.toLowerCase() === "si"
            };
        },
        mostrarNotas(operario, id){
            
                this.cargarNotas(operario, id);            
            
        },
        getKeyByValue(object, value) {
            return Object.keys(object).find(key => object[key] === value);
        },
        togglecargar() { 
            this.cargar = !this.cargar;
        },
        getPerjudicados(operario) {
            if (!operario || typeof operario !== 'object' || Object.keys(operario).length === 0) {
                return [];
            }
            return Object.keys(operario)
                .filter(key => key.startsWith('Perjudicado_'))
                .map(key => operario[key]);
        }
    },
    mounted() {
        this.urlglobal = localStorage.getItem("direccion");
        if (this.operariosingular) {
        // Si 'operariosingular' es verdadero, cargamos los datos nosotros mismos
            this.cargardatos();
        } else if (this.operarios && Object.keys(this.operarios).length > 0) {
            // Si 'operarios' está disponible y 'operariosingular' es falso o indefinido, usamos los datos del padre
            this.localOperarios = JSON.parse(JSON.stringify(this.operarios));
        }
        this.cargarDetalle();
        
    },
    watch: {
        actualizartotales(){
            console.log('llegaactualizartotales');
            this.actualizartot = !this.actualizartot;
        },
        id() { 
            this.localOperarios = [];
            this.cargarDetalle();

            //this.cargardatos();
        },
        cargar() { 
            //this.cargardatos();
        },
        recargar() {
            //this.cargardatos();
        },
        operarios(newVal) {
            if (newVal && Object.keys(newVal).length > 0 ) {
                // Assign the new prop value to the local data property
                this.localOperarios = JSON.parse(JSON.stringify(newVal));
            }
        },
        localOperarios(){
            console.log('localoperarios2', this.localOperarios);
        },
        operariosingular(newVal) {
            if (newVal) {
                // Si 'operariosingular' cambia a verdadero, cargamos los datos nosotros mismos
                this.cargardatos();
            } else {
                // Si 'operariosingular' cambia a falso, usamos los datos del padre si están disponibles
                if (this.operarios && Object.keys(this.operarios).length > 0) {
                    this.localOperarios = JSON.parse(JSON.stringify(this.operarios));
                }
            }
        }
    }
})
</script>
<style>
.p-galleria-close {

    top: 10%;
    right: 10%;

}
.p-progressbar .p-progressbar-value{
    background: #28a745 !important;
}

</style>