<template>
    <div class="card">
        <div class="card-header bg-default">
            <h3 class="card-title"><i class="fas fa-images" style="margin-right: 5px;"></i>Fotos de visita</h3>
            <div class="card-tools">
                <div class="custom-control custom-switch custom-switch">
                    <input type="checkbox" v-model="visibles" class="custom-control-input" id="sw">
                    <label class="custom-control-label" for="sw"></label>
                    <!-- Spinner o indicador de carga -->
                </div>
            </div>                          
        </div>
        <div class="card-body" v-if="visibles">
            <div class="custom-control custom-checkbox" style="margin-bottom: 10px;">
                <input id="ch" class="custom-control-input" type="checkbox" @change="marcartodo()" v-model="todos">
                <label for="ch" class="custom-control-label">Marcar/Desmarcar todas</label>
            </div>
            <div v-if="cargados" class="form-group">
                <TabView :scrollable="true">
                    <TabPanel v-for="visita in visitas" :key="visita"
                        :header="new Date(visita.visita.fecha).toLocaleDateString() + ' ' + visita.visita.hora.slice(0, -3) + ' - ' + visita.visita.nombre_operario">
                        <div class="row">
                            <div class="col-sm-4" v-for="archivos in visita.archivos" :key="archivos">
                                <!---->
                                <div
                                    v-if="archivos.documento.substr(archivos.documento.length - 3) == 'jpg' || archivos.documento.substr(archivos.documento.length - 3) == 'png' || archivos.documento.substr(archivos.documento.length - 4) == 'jpeg' || archivos.documento.substr(archivos.documento.length - 3) == 'bmp' || archivos.documento.substr(archivos.documento.length - 3) == 'gif' || archivos.documento.substr(archivos.documento.length - 4) == 'tiff' || archivos.documento.substr(archivos.documento.length - 4) == 'webp'">
                                    <Image v-if="urlglobal != 'null.pwgs.app'"
                                        :src='archivos.url_completa_descarga'
                                        :id="archivos.id_documento" width="200" preview :alt="archivos.descripcion" />
                                    <Image v-if="urlglobal == 'null.pwgs.app'"
                                        :src='"https://desarrollofront.pwgs.app/documentos.php?documento=" + archivos.documento + "&servicio=" +id '
                                        :id="archivos.id_documento" width="200" preview :alt="archivos.descripcion" />
                                    <div v-if="archivos.cuando" style="text-align: center;">
                                        <span style="margin-right: 5px;" v-if="archivos.cuando=='despues'" class="badge bg-blue">después</span>
                                        <span style="margin-right: 5px;" v-else class="badge bg-blue">{{archivos.cuando}}</span>
                                    </div>
                                </div>
                                <div :title="archivos.documento" class="row">
                                    <div class="col-md-1">
                                        <input :id="archivos.id_documento"
                                            @change="marcar(archivos)"
                                            v-model="archivos.checked" type="checkbox">
                                    </div>
                                    <div class="col-md-10">
                                        <label v-if="archivos.documento.valueOf().length>15" :for="archivos.id_documento">
                                            {{ archivos.documento.substring(0,15)+'...' }} </label>
                                        <label v-if="archivos.documento.valueOf().length <= 15"
                                            :for="archivos.id_documento">
                                            {{ archivos.documento}} </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                </TabView>
            </div>
        </div>
    </div>    
</template>
<script>
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Image from 'primevue/image';
import { PwgsApi } from '../../../../../../services/PwgsApi';

export default ({
            props: ['id','seleccionado'],

    data() {
        return {
            todos:false,
            visibles:false,
            cargados:false,
            presupuestosaux: '',
            visitas:[],
            marcados:[],
        }
    },
    components: {
        TabPanel,TabView,Image
    },
    methods: {
        marcar(archivo){
            console.log('arch',archivo);
            if(archivo.checked){
                this.marcados.push(archivo.documento);
            }else{
                this.marcados = this.marcados.filter(doc=>doc!= archivo.documento);
            }
            this.$emit('marcar', this.marcados);
        },
        marcartodo(){
            this.marcados = [];
            for(var visita of this.visitas){
                for(var archivos of visita.archivos){
                    archivos.checked = this.todos;
                    if(this.todos){
                        this.marcados.push(archivos.documento);
                    }
                }
            }
            this.$emit('marcar', this.marcados);
        },
        async cargardatos() {
            const api = new PwgsApi();
            this.visitas = await api.get('servicios/'+this.id+'/visitas');
            this.presupuestosaux = this.seleccionado;
            console.log('selec', this.presupuestosaux);
            this.cargados = true;
        },
        async guardar(operario) {
            const api = new PwgsApi();
            try{
                console.log('operarioselec',operario);
                if(operario.marcado == '1'){
                    this.idopers.push(operario.id);
                }else{
                    this.idopers = this.idopers.filter(id=>id!= operario.id);
                }
                await api.put('presupuestos/'+this.presupuestosaux.presupuesto.id_presupuesto+'/modificar-presupuesto',{operarios:this.idopers});
            }catch(e) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: e, life: 5000 });
            }
        },
    },
    mounted() {
        this.cargardatos();
    },
    watch: {
        seleccionado() {
            this.cargardatos();
        },
                            

    }
})
</script>