<template>
    <Dialog v-model:visible="$parent.visibleConfirmar" modal header="Confirmar visita" :style="{width: '800px'}" @hide="this.$parent.visibleConfirmar = false">
        <div class="centro">
            <h3 style="text-align: center;">Inicio</h3>
            <div class="row" style="justify-content: center;column-gap: 15px;">
                <div style="display: grid;">
                    <label for="fecha">Fecha</label>
                    <input type="date" id="fecha" v-model="fechaSeleccionadaInicio">
                </div>
                <div style="display: grid;">
                    <label for="hora">Hora</label>
                    <input type="time" id="hora" v-model="horaSeleccionadaInicio">
                </div>
            </div>
            <h3 style="margin-top: 30px;text-align: center;">Fin</h3>
            <div class="row" style="justify-content: center;column-gap: 15px;">
                <div style="display: grid;">
                    <label for="fecha">Fecha</label>
                    <input type="date" id="fecha" v-model="fechaSeleccionadaFin">
                </div>
                <div style="display: grid;">
                    <label for="hora">Hora</label>
                    <input type="time" id="hora" v-model="horaSeleccionadaFin">
                </div>
            </div>
        </div>
        <div class="final">                 
            <div style="display: grid;align-items: end;">
                <button class="btn btn-sm btn-light" style="border:1px solid grey" @click=" confirmarCita()">Confirmar</button>
            </div>
        </div>
        <div class="pie">
            <p style="margin:0 ;">
                Operario: {{ operario }}
            </p>
            <p>
                Siniestro: {{ siniestro }}  
            </p> 
        </div>                  
            
        
    </Dialog>
</template>
<script>
import { PwgsApi } from '../../../../../services/PwgsApi';
import Dialog from 'primevue/dialog';
export default ({
    emits: [
        'cargarplanning','actualizar'
    ],
    props: ['idservicio','idvisita', 'visita', 'citasfinalizadas'],
    components:{
        Dialog,
    },
    data(){
        return{
            operario: this.visita.visita.nombre_operario,
            siniestro: this.visita.visita.codigo_servicio,
            fechaSeleccionadaFin: this.visita.visita.fecha_fin,
            fechaSeleccionadaInicio: this.visita.visita.fecha,
            horaSeleccionadaFin: this.visita.visita.hora_fin,
            horaSeleccionadaInicio: this.visita.visita.hora,
        }
    },
    mounted(){
    },
    methods:{
        async confirmarCita(){
            const api = new PwgsApi();
            const body = {fecha_cita_inicio: this.formatearFecha(this.fechaSeleccionadaInicio), fecha_cita_fin: this.formatearFecha(this.fechaSeleccionadaFin), 
                hora_cita_inicio: this.horaSeleccionadaInicio, hora_cita_fin: this.horaSeleccionadaFin};
            console.log("paparrent", this.$parent);
            try {
                
                
                const resp = await api.put('planning-pwgs/' + this.$props.visita.visita.datos.servicios_idservicios +'/confirmar-visita/'+this.idvisita, body);
                console.log('confirvisi', resp);
                this.$parent.visibleConfirmar = false;
                
            }catch(e){
                alert(e);
                console.error('error',e);
            }
            this.$emit("cargarplanning");
            this.$emit("actualizar");

        },
        formatearFecha(fecha){
            const fechaSplit = fecha.split('-');
            return `${fechaSplit[2]}/${fechaSplit[1]}/${fechaSplit[0]}`;
        }
    },
    watch:{
        
    }
});
</script>
<style>

.pie{
    display:grid;
    justify-content: end;
}
.final{
    display: flex;
    column-gap: 15px;
    margin-top: 30px;
    justify-content: center;
}
.centro{
    display: grid;
    justify-content: center;
}
</style>