<template>
    <div class="callout callout-danger overlay-wrapper" style="position: relative;">
        <div class="overlay" v-if="isGuardando == true"><i class="fas fa-3x fa-sync-alt fa-spin"></i>
            <div class="text-bold pt-2">{{$t('general.guardando')}}...</div>
        </div>
        <div :title="this.mensajenoeliminable" v-if="iseliminable==false" class="ribbon ribbon-top-left"><span>No
                eliminable</span>
        </div>
        <div class="row">
            <div class="col">
                <div class="input-group" v-if="mostrar.id_baremo != 0">
                    <button type="button" :style="estiloreducido"
                        v-if="bloqueos.id_baremo != 1 &&  work.trabajos_compañia_idtrabajo==0"
                        @click="toggleSelect = !toggleSelect" class="btn btn-default btn-flat">
                        <i class="fas fa-list"></i>
                    </button>
                    <v-select v-if="especialidadesb" v-model="desplegable" :options="especialidadesb"
                        @change="toggleSelect = !toggleSelect" v-show="toggleSelect" />
                    <input :style="estiloreducido"
                        :disabled="iseliminable==false || work.trabajos_compañia_idtrabajo!=0" type="text"
                        @input="autosave=true" class="form-control input-sm" v-model="work.codigo" placeholder="Código">
                </div>
                <div class="form-group">
                    <textarea :disabled="iseliminable==false || work.trabajos_compañia_idtrabajo!=0"
                        @input="autosave=true" class="form-control input-sm" v-model="work.nombre"
                        placeholder="Concepto"></textarea>
                </div>
            </div>
            <div class="col">
                <div class="form-group">
                    <textarea :disabled="iseliminable==false" class="form-control input-sm"
                        v-if="mostrar.descripcion_factura != 0" v-model="work.descripcion_factura"
                        placeholder="Descripción" rows="4"></textarea>
                </div>
            </div>
            <div class="col">
                <div class="input-group " v-if="mostrar.precio != 0">
                    <div class="input-group-prepend">
                        <span :style="estiloreducido" class="input-group-text input-sm">{{ $t('general.precio')
                            }}</span>
                    </div>
                    <input :style="estiloreducido" :disabled="iseliminable==false" type="Number" @input="autosave=true"
                        v-model="work.precio" class="form-control input-sm" placeholder="Precio">
                </div>
                <div class="input-group " v-if="mostrar.precio_operario != 0">
                    <div class="input-group-prepend">
                        <span :style="estiloreducido" class="input-group-text">{{ $t('general.precio') }} Op.</span>
                    </div>
                    <input :style="estiloreducido" :disabled="iseliminable==false" type="Number" @input="autosave=true"
                        v-model="work.precio_operario" class="form-control" placeholder="Precio Operario">
                </div>
                <div class="input-group " v-if="mostrar.cantidad != 0">
                    <div class="input-group-prepend">
                        <span :style="estiloreducido" class="input-group-text">{{ $t('general.cantidad') }}</span>
                    </div>
                    <input :style="estiloreducido" :disabled="iseliminable==false" type="Number" @input="autosave=true"
                        v-model="work.cantidad" class="form-control" placeholder="Cantidad">
                </div>
            </div>
            <div class="col" style="max-width: 170px" v-if="mostrar.descuento != 0">
                <select :style="estiloreducido" :disabled="iseliminable==false" @input="autosave=true"
                    v-if="mostrar.desc_incr" v-model="work.desc_incr" class="form-control input-sm">
                    <option value="0">Descuento</option>
                    <option value="1">Incremento</option>
                </select>
                <div class="input-group">
                    <input :style="estiloreducido" :disabled="iseliminable==false" @input="autosave=true" type="number"
                        class="form-control" v-model="work.descuento">
                    <div class="input-group-append">
                        <span :style="estiloreducido" class="input-group-text">%</span>
                    </div>
                </div>
                <div class="input-group">
                    <input :style="estiloreducido" :disabled="iseliminable==false" @input="autosave=true" type="number"
                        class="form-control" v-model="work.descuento_importe">
                    <div class="input-group-append">
                        <span :style="estiloreducido" class="input-group-text">&euro;</span>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="input-group ">
                    <div class="input-group-prepend">
                        <span :style="estiloreducido" class="input-group-text">{{ $t('general.total') }}</span>
                    </div>
                    <input :style="estiloreducido" type="text" class="form-control" v-model="work.precio"
                        placeholder="Precio" readonly>
                </div>
                <div class="input-group ">
                    <div class="input-group-prepend">
                        <span :style="estiloreducido" class="input-group-text">{{ $t('general.total') }} Op.</span>
                    </div>
                    <input :style="estiloreducido" type="text" class="form-control" v-model="work.precio_operario"
                        placeholder="Precio Operario" readonly>
                </div>
            </div>
            <div class="col">
                <div class="input-group" v-if="mostrar.iva_trabajo != 0">
                    <div class="input-group-prepend">
                        <span :style="estiloreducido" class="input-group-text">{{ $t('general.impuesto') }}</span>
                    </div>
                    <select :style="estiloreducido" :disabled="iseliminable==false" @input="autosave=true"
                        v-model="work.iva_trabajo" class="form-control input-sm">
                        <option v-for="impuesto in impuestos" :key="impuesto.id" :value="impuesto.valor_iva">{{
                            impuesto.valor_iva }}</option>
                    </select>
                </div>
                <div v-if="mostrar.destinatario_factura" class="input-group" title="Destinatario">
                    <div class="input-group-prepend">
                        <span :style="estiloreducido" class="input-group-text">Dest.</span>
                    </div>
                    <select :style="estiloreducido" :disabled="iseliminable==false" @input="autosave=true"
                        v-model="work.destinatario_presupuesto_trabajo" class="form-control input-sm">
                        <option value="Compañia">{{ $t('general.compania') }}</option>
                        <option value="Cliente">{{ $t('general.asegurado') }}</option>
                    </select>
                </div>
                <div class="input-group" title="Daños">
                    <div class="input-group-prepend">
                        <span :style="estiloreducido" class="input-group-text">{{ $t('general.danyos') }}</span>
                    </div>
                    <select :style="estiloreducido" :disabled="iseliminable==false" @input="autosave=true"
                        v-if="mostrar.tipo_dano" v-model="work.tipo_dano" class="form-control input-sm">
                        <option value="esteticos">Estéticos</option>
                        <option value="directos">Directos</option>
                    </select>
                </div>
            </div>
            <div class="col" style="max-width: 150px">
                <div class="custom-control custom-switch custom-switch-on-success">
                    <input :disabled="iseliminable==false" v-model="aprobar_trabajo" type="checkbox"
                        class="custom-control-input" @change="marcarAprobado()"
                        :id="'aprobado_' + $props.trabajo.datos.idtrabajo_presupuesto">
                    <label class="custom-control-label"
                        :for="'aprobado_' + $props.trabajo.datos.idtrabajo_presupuesto">{{ $t('general.aprobado')
                        }}</label>
                </div>
                <div class="custom-control custom-switch custom-switch-on-indigo">
                    <input :disabled="iseliminable==false" v-model="estado_trabajo" type="checkbox"
                        class="custom-control-input" @change="marcarEstado()"
                        :id="'estado_' + $props.trabajo.datos.idtrabajo_presupuesto">
                    <label class="custom-control-label" :for="'estado_' + $props.trabajo.datos.idtrabajo_presupuesto">{{
                        $t('general.subsanado') }}</label>
                </div>
                <div class="custom-control custom-switch">
                    <input v-model="facturar_trabajo" type="checkbox" class="custom-control-input"
                        @change="marcarfacturar()" :id="'facturar_' + $props.trabajo.datos.idtrabajo_presupuesto">
                    <label class="custom-control-label"
                        :for="'facturar_' + $props.trabajo.datos.idtrabajo_presupuesto">{{ $t('general.facturar')
                        }}</label>
                </div>
            </div>
            <div class="col" style="max-width:105px;min-width:105px">
                <div class="btn-group d-flex justify-content-end">
                    <button :disabled="iseliminable==false" @input="autosave=false" @click="guardar()"
                        class="btn btn-primary"><i class="fas fa-save"></i></button>
                    <button :disabled="iseliminable==false" @input="autosave=false" @click="eliminar()"
                        class="btn btn-danger"><i class="fas fa-times"></i></button>
                </div>
                <div class="input-group " v-if="mostrar.orden != 0">
                    <input title="Orden en factura (menor número mayor prioridad)" :disabled="iseliminable==false"
                        type="Number" @input="autosave=true" v-model="work.orden_presupuesto_trabajo"
                        class="form-control" placeholder="Orden" style="max-width: 90px">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import debounce from 'debounce';
import { PwgsApi } from '../../../../../../services/PwgsApi';
export default ({
    props: ['trabajo','seleccionado','id'],
    setup() {
        return;
    },
    components: {
    },
    data() {
        return {
            autosave: false,
            work: this.trabajo.datos,
            mostrar: [],
            bloqueos: [],
            toggleSelect: false,
            idtrabajos: '',
            especialidadesa: [],
            especialidadesb: [],
            especialidadesbid: [],
            desplegable: [],
            iseliminable:true,
            mensajenoeliminable:'',
            miid: '',
            trabajoseleccionado: [],
            renderComponent: true,
            facturar_trabajo: false,
            estado_trabajo: false,
            aprobar_trabajo: false,
            isGuardando:false,
            guardadoAutomatico: null,  // Almacenará la función de debounce para guardardatos
            isGuardadoAutomaticoPendiente: false, // Variable que indica si hay un autoguardado pendiente
            impuestos: [],
            estiloreducido: 'height:2em',

        }
    },
    methods: {
        cargardatos() {

            console.log("Carga datos");
            this.work = [];
            this.work = this.$props.trabajo.datos;
            this.mostrar = this.$props.trabajo.opciones.mostrar;
            this.bloqueos = this.$props.trabajo.opciones.bloqueos;
            var eliminable = this.$props.trabajo.opciones.eliminable.estado;
            console.log('work', this.trabajo);
            if(eliminable != '1'){
                this.iseliminable = false;
                this.mensajenoeliminable = this.$props.trabajo.opciones.eliminable.mensaje;
            }

            if(this.work.incluir_trabajo == 1){ this.facturar_trabajo = true; }
            if(this.work.estado_trabajo_presupuesto	 == "Subsanado"){ this.estado_trabajo = true; }
            if(this.work.aprobado_presupuesto_trabajo	 == "SI"){ this.aprobar_trabajo = true; }
            


        },
        autoguardado() {
            if (this.autosave) {
                this.isGuardadoAutomaticoPendiente = true; // Marcar el autoguardado pendiente inmediatamente
                this.guardadoAutomatico();
                this.autosave = false; // Resetear autosave después de guardar
            }
        },
        // Cancelar autoguardado pendiente
        cancelarAutoguardado() {
            if (this.guardadoAutomatico) {
                this.guardadoAutomatico.clear(); // Cancela cualquier autoguardado pendiente
                this.autosave = false;
                this.isGuardadoAutomaticoPendiente = false; //
            }
        },
        async guardar() {
            //PUT  modulos/pwgsapi/index.php/presupuestos/:id_trabajo/modificar-trabajo
            this.isGuardando = true; // Activar el estado de guardando
            //Cancelamos guardados pendientes si le damos desde el botón también.
            if (this.isAutoguardadoPendiente()) {
                this.cancelarAutoguardado();
            }
            const api = new PwgsApi;
            let subidadatos;
              try {
                subidadatos = {
                    codigo:this.work.codigo, 
                    id_baremo: this.work.id_baremo, 
                    nombre: this.work.nombre, 
                    descripcion_factura: this.work.descripcion_factura, 
                    precio: this.work.precio, 
                    precio_operario: this.work.precio_operario, 
                    descuento: this.work.descuento, 
                    descuento_importe: this.work.descuento_importe, 
                    descuento_aplicado: this.work.descuento_aplicado, 
                    cantidad: this.work.cantidad, 
                    aprobado: this.work.aprobado_presupuesto_trabajo, 
                    estado: this.work.estado_trabajo_presupuesto, 
                    destinatario_factura: this.work.destinatario_presupuesto_trabajo, 
                    tipo_dano: this.work.tipo_dano, 
                   // orden: this.work.orden, 
                    facturar: this.work.incluir_trabajo, 
                    desc_incr: this.work.desc_incr, 
                    tipo_trabajo: this.work.tipo_trabajo, 
                    ancho_cristal: this.work.ancho_cristal, 
                    alto_cristal: this.work.alto_cristal, 
                    metro_lineal: this.work.metro_lineal,
                    iva:  this.work.iva_trabajo,
                    orden: this.work.orden_presupuesto_trabajo
                }
                await api.put('presupuestos/' + this.$props.trabajo.datos.idtrabajo_presupuesto + '/modificar-trabajo', subidadatos);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Error', life: 5000 });
            }finally {
                this.isGuardando = false;
            }  
        },
        isAutoguardadoPendiente() {
            return this.isGuardadoAutomaticoPendiente;
        },
        async eliminar() { 
            if(window.confirm('¿Quiere eliminar el trabajo del presupuesto?')){
                //DELETE  modulos/pwgsapi/index.php/presupuestos/:id_trabajo/eliminar-trabajo
                const api = new PwgsApi;
                try {
                    await api.delete('presupuestos/' + this.$props.trabajo.datos.idtrabajo_presupuesto + '/eliminar-trabajo');
                    this.$toast.add({ severity: 'success', summary: 'Eliminado', detail: 'El trabajo fue eliminado correctamente', life: 1000 });
                    this.$parent.cargartrabajos();
                    this.$parent.cargartrabajospresupuesto();
                    this.cargardatos();
                } catch (error) {
                    this.$toast.add({ severity: 'error', summary: 'Error al eliminar', detail: error, life: 5000 });
                    
                }
            }
        },
        async listartrabajos(){
                // POST  modulos/pwgsapi/index.php/servicios/id_servicio/baremo
            const api = new PwgsApi;
            let subidadatos = { id_especialidad: this.$props.trabajo.datos.especialidades_idespecialidad };
            this.especialidadesa = await api.post('servicios/' + this.$props.id + '/baremo', subidadatos);
                for (let i = 0; i < this.especialidadesa.length; i++) {
                    this.especialidadesb.push(this.especialidadesa[i].nombre);
                    this.especialidadesbid.push(this.especialidadesa[i].idtrabajo);
                }
        },
        async cambiartrabajo() {
            //GET  modulos/pwgsapi/index.php/trabajos/:id_trabajo
            for (let i = 0; i < this.especialidadesb.length; i++){
                if (this.especialidadesb[i] == this.desplegable) {
                    this.trabajoseleccionado = this.especialidadesa[i];

                    //PUT  modulos/pwgsapi/index.php/presupuestos/:id_trabajo/modificar-trabajo
                    const api = new PwgsApi;
                    let subidadatos;
                    try {
                        subidadatos = { codigo: this.trabajoseleccionado.codigo, id_baremo: this.trabajoseleccionado.id_baremo, nombre: this.trabajoseleccionado.nombre,precio: this.trabajoseleccionado.precio, precio_operario: this.trabajoseleccionado.precio_operario, cantidad: this.trabajoseleccionado.cantidad,destinatario: this.trabajoseleccionado.destinatario}
                        await api.put('presupuestos/' + this.$props.trabajo.datos.idtrabajo_presupuesto + '/modificar-trabajo', subidadatos);
                        this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
                    }
                    catch (error) {
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: 'El trabajo no se puede modificar ya que está incluido en otro presupuesto generado', life: 5000 });
                    }
                }
            }
            this.$parent.$parent.$parent.cargardatos(this.$props.id);  
        },
        marcarfacturar (){
            if (this.facturar_trabajo==true) { 
                this.work.incluir_trabajo = 1; 
            }else{
                this.work.incluir_trabajo = 0; 
            }
            this.guardar();
        },
        marcarEstado (){
            if (this.estado_trabajo==true) { 
                this.work.estado_trabajo_presupuesto = 'Subsanado'; 
            }else{
                this.work.estado_trabajo_presupuesto = ''; 
            }
            this.guardar();
        },
        marcarAprobado (){
            if (this.aprobar_trabajo==true) { 
                this.work.aprobado_presupuesto_trabajo = 'SI'; 
            }else{
                this.work.aprobado_presupuesto_trabajo = ''; 
            }
            this.guardar();
        },
        async cargarImpuestos(){
            const api = new PwgsApi();
            const ress = await api.get('impuestos');
            this.impuestos = ress.datos;
        },
    },
    mounted() {
        this.cargardatos();
        this.listartrabajos();
        this.cargarImpuestos();
    },
    created() {
        // Creación de la función de debounce con un retraso de 5000 ms
        this.guardadoAutomatico = debounce(() => {
            // Crear una función async para manejar el guardado
            const guardar = async () => {
                await this.guardar(); // Guardar los datos
            };
            guardar(); // Ejecutar la función async
        }, 5000); // 5 segundos de espera tras el último cambio antes de guardar
    },
    watch: {
        seleccionado() {
            this.cargardatos();
        },
        desplegable() { 
            this.cambiartrabajo(); 
        },
        autosave(val) {
            if (val === true) {
                this.autoguardado(); // Ejecuta el guardado cuando autosave es true
            }
        }
    }
})
</script>
<style scoped>
.ribbon {
  width: 100px;
  height: 100px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #2980b9;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 175px;
  padding: 5px 0;
  background-color: #3498db;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  color: #fff;
  font: 700 10px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-transform: uppercase;
  text-align: center;
  z-index: 20;
}
/* top left*/
.ribbon-top-left {
  top: -4px;
  left: -8px;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-top-left::before {
  top: 0;
  right: 0;
}
.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}
.ribbon-top-left span {
  right: -20px;
  top: 25px;
  transform: rotate(-45deg);
}
/* top right*/
.ribbon-top-right {
  top: -10px;
  right: -10px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-top-right::before {
  top: 0;
  left: 0;
}
.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}
.ribbon-top-right span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
}
/* bottom left*/
.ribbon-bottom-left {
  bottom: -10px;
  left: -10px;
}
.ribbon-bottom-left::before,
.ribbon-bottom-left::after {
  border-bottom-color: transparent;
  border-left-color: transparent;
}
.ribbon-bottom-left::before {
  bottom: 0;
  right: 0;
}
.ribbon-bottom-left::after {
  top: 0;
  left: 0;
}
.ribbon-bottom-left span {
  right: -25px;
  bottom: 30px;
  transform: rotate(225deg);
}
.vs__dropdown-menu{
    width:30em !important;
}
/* bottom right*/
.ribbon-bottom-right {
  bottom: -10px;
  right: -10px;
}
.ribbon-bottom-right::before,
.ribbon-bottom-right::after {
  border-bottom-color: transparent;
  border-right-color: transparent;
}
.ribbon-bottom-right::before {
  bottom: 0;
  left: 0;
}
.ribbon-bottom-right::after {
  top: 0;
  right: 0;
}
.ribbon-bottom-right span {
  left: -25px;
  bottom: 30px;
  transform: rotate(-225deg);
}
</style>